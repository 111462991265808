import React,{ useEffect, useRef, useState } from "react";
import { createRoot } from 'react-dom/client';
import {
    Label,
    Button,
    FormFeedback,
    ButtonGroup,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { propertyListGetRequest } from "store/auth/action";
import { ApplicationState } from "store";


interface DtsCKEditorProps {
    label?: string;
    type?: string;
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    isEditable?: boolean;
    name?: string;
    errorText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    actionButtons?: boolean;
    defaultValue?: string;
    rest?: any;
    invalid?: any;
    formValues?: any;
    dataFields?: any;
    onSaveClick?: any;
    setdisabled?: any;
    setError?: any;
    formerror?: any;
    resetvalue?: any;
}

const DtsCKEditor: React.FC<DtsCKEditorProps> = ({ resetvalue, formerror, setError, setdisabled, onSaveClick, rest, defaultValue, label, type, value, placeholder, onChange, name, icon, iconPosition, actionButtons, readOnly, disabled }: any) => {
    const [hover, setHover] = useState<any>(false)
    const optionList = useSelector((state: ApplicationState) => state.auth.optionmetaData);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [checkdisabled, setCheckdisabled] = useState<any>(false)
    const [errormsg, setErrormsg] = useState();
    const [suggestionsVisible, setSuggestionsVisible] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);
    const [suggestions, setSuggestion] = useState<any>([])
    const [editor, setEditor] = useState<any>([])
    const [resetValue, setResetValue] = useState<any>(null)
    const [suggestionContainer, setsuggestionContainer] = useState<any>(null)
    const onhandleChange = (value: any) => {
        onChange(value)
        validateInput(value);
    }

    
const editorConfig = {
    removePlugins: ['Table', 'TableToolbar', 'TableTools', 'InsertTable', 'about'],
  };


    useEffect(() => {
        const suggestionContainer = document.createElement('div');
        suggestionContainer.className = 'suggestion-container';
        suggestionContainer.style.cssText = `
            position: absolute;
            background-color: #f9f9f9;
            border: 1px solid #ccc;
            padding: 10px;
            z-index: 2000;
            width: 200px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            right:0,
            top:50,
            min-height: 200px; /* adjust to your desired minimum height */
            max-height: 300px; /* adjust to your desired maximum height */
            overflow-y: auto; /* or overflow: auto for both x and y axes */
            `;
            setsuggestionContainer(suggestionContainer)
    }, [suggestionContainer == null])

    useEffect(() => {
        onChange && onChange(resetvalue)
        setResetValue(resetvalue)
    }, [resetvalue])

    const validateInput = (value: any) => {
        if (rest && rest.type == 'email') {
            if (value === '' || !/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                let error: any = `Please enter valid ${label}`;
                setErrormsg(error)
                if (typeof setError === 'function') {
                    setError({ ...formerror, [name]: error })
                }
                setCheckdisabled(true)
                return false
            }
        }
        if (rest && rest.validate && rest.validate.required) {
            if (value === "") {
                let error: any = `Please enter ${label}`;
                setErrormsg(error)
                if (typeof setError === 'function') {
                    setError({ ...formerror, [name]: error })
                }
                setCheckdisabled(true)
                return false
            }
        }
        if (rest && rest.validate && rest.validate.minLength) {
            if (value.length < rest.validate.minLength) {
                let error: any = `Length of ${label} must be atleast ${rest.validate.minLength}`;
                setErrormsg(error)
                if (typeof setError === 'function') {
                    setError({ ...formerror, [name]: error })
                }
                setCheckdisabled(true)
                return false
            }
        }
        if (rest && rest.validate && rest.validate.maxLength) {
            if (value.length > rest.validate.maxLength) {
                let error: any = `Length of ${label} must be not more than ${rest.validate.maxLength}`;
                setErrormsg(error)
                if (typeof setError === 'function') {
                    setError({ ...formerror, [name]: error })
                }
                setCheckdisabled(true)
                return false
            }
        }
        setErrormsg(undefined)
        if (typeof setError === 'function') {
            const { [name]: _, ...newObject } = formerror;
            setError(newObject)
        }
        setCheckdisabled(false)
        return true
    }

    useEffect(() => {
        if (rest && rest.validate) {
            if (errormsg) {
                if (typeof setdisabled === 'function') {
                    setdisabled(true)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if (inputdiv) inputdiv.classList.add('d-block');
            }
            else {
                if (typeof setdisabled === 'function') {
                    setdisabled(false)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if (inputdiv) inputdiv.classList.remove('d-block');
            }
        }
    }, [value])

    const onCheckClick = (e: any) => {
        e.preventDefault();
        setHover(false)
        onSaveClick()
    }
    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(propertyListGetRequest("students", filters));
    }, [])

    useEffect(() => {
        if (rest && rest.validate && rest.validate.required) {
            const feedback: any = document.getElementById(`starred${name}`);
            if (feedback) feedback.classList.remove('d-none');
        }
    }, [rest])

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (inputRef && inputRef.current && !inputRef.current.contains(event.target)) {
                setHover(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [inputRef]);
    
    useEffect(() => {
        let options: any = optionList && optionList.length && optionList.map((item: any) => {
            let valueJson = item.valuesJson ? JSON.parse(item.valuesJson) : {}
            return { label: valueJson.label, value: valueJson.key, valueJson: valueJson }
        })
        setSuggestion(options)
    }, [optionList])

    const optionClick = (option: any) => {
          const currentPosition = editor.model.document.selection.getFirstPosition();
            const currentParagraph = currentPosition?.parent;
            editor.model.change((writer: any) => {
                writer.appendText(`${option.value}}}`, currentParagraph);
            });
        }
    useEffect(() => {
        setTimeout(() => {
          if (editor && editor.model) {      
            editor.model.document.on('change:data', () => {
              const selection = editor.model.document.selection;
              const firstPosition = selection.getFirstPosition();
              const offset = firstPosition?.offset - 2;
      
              if (offset >= 0) {
                const text = editor.getData();
                const openCount = (text?.match(/{{/g) || []).length;
                const closeCount = (text?.match(/}}/g) || []).length;
                if (openCount > closeCount && suggestions.length && suggestionsVisible) {
                  // Show suggestions
                  const suggestionList = suggestions.map((suggestion: any) => (
                    <div key={suggestion.value} className="cursor-pointer" onClick={(e: any) => optionClick(suggestion)}>
                      {suggestion.label}
                    </div>
                  ));
                  const root = createRoot(suggestionContainer);
                  root.render(
                    <div>
                      {suggestionList}
                    </div>
                  );                  

                const selection: any = window.getSelection();
                const range = selection.getRangeAt(0);
                const rect = range.getBoundingClientRect();
                // Position suggestion container
                suggestionContainer.style.top = `${rect.top + rect.height}px`;
                suggestionContainer.style.left = `${rect.left}px`;

                document.body.appendChild(suggestionContainer);
                 }
                 else {
                  // Remove suggestion container if already exists
                  if (suggestionContainer?.parentNode) {
                    suggestionContainer?.parentNode.removeChild(suggestionContainer);
                  }
                }      
                if (openCount == closeCount) {
                    if (suggestionContainer?.parentNode) {
                        suggestionContainer?.parentNode.removeChild(suggestionContainer);
                      }
                }
                
              }
            });
          }
        }, 100);
      }, [editor, suggestions]);
      
    return (
        <div className="pb-3">
            {
                actionButtons ?
                    <div>
                        {hover ?
                            <div>
                                {label ? <Label htmlFor={name} className="form-label fw-bold fs-14">{label}<span id={`starred${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label> : null}
                                <div className="hstack border rounded-3 align-items-center w-100">
                                    <div className="w-100 search-box">
                                        <CKEditor
                                            editor={ClassicEditor}
                                            config={editorConfig}
                                            data={value?value:''}
                                            id={'editorui'}
                                            onChange={(event, editor) => {
                                                setEditor(editor)
                                                let data = editor.getData();
                                                onhandleChange(data)
                                            }}
                                            disabled={disabled}
                                            onReady={(editor: any) => {
                                            
                                            }}
                                        />
                                    
                                        <ButtonGroup  className="mt-4 mt-sm-0" style={{right:1, left:'auto', top:1, position: "absolute"}}>
                                            <Button onClick={(e) => { e.preventDefault(); setHover(false); onChange(resetValue) }} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button>
                                            <Button onClick={(e) => { onCheckClick(e) }} disabled={checkdisabled} color="primary"  className="btn-icon"> <i className="ri-check-fill" /> </Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                                {<FormFeedback id={`${name}feedback`}>{errormsg}</FormFeedback>}
                            </div>
                            : <div>
                                {label ? <Label htmlFor={name} className="form-label fw-bold fs-14 mb-0">{label}<span id={`starred${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label> : null}
                                <div className="hstack justify-content-between topbar-user">
                                 <div className="fs-14 fw-medium form-control border-0 topbar-user ps-2" style={{height: 'auto'}}>
                                        <div dangerouslySetInnerHTML={{ __html: value }} />
                                    </div>
                                    {
                                        readOnly ? null
                                            : <Button onClick={() => setHover(true)} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-edit-2-fill" /> </Button>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div>
                        {label ? <Label htmlFor={name} className="form-label fw-bold fs-14 text-nowrap text-truncate text-nowrap-ellipsis">{label}<span id={`starred${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label> : null}
                        <CKEditor
                            editor={ClassicEditor}
                            config={editorConfig}
                            data={value?value:''}
                            id={'editorui'}
                            onChange={(event, editor) => {
                                setEditor(editor)
                                let data = editor.getData();
                                onhandleChange(data)
                            }}
                            disabled={disabled}
                            onReady={(editor: any) => {
                              
                            }}
                        />
                        {<FormFeedback id={`${name}feedback`}>{errormsg}</FormFeedback>}
                    </div>
            }
        </div>
    );
};

export default DtsCKEditor;
