import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
    Modal,
    ModalBody,
    Button,
    ModalHeader,
    ListGroup,
    ListGroupItem,
    Label,
    Input,
    Badge,
    Col,
    Row,
} from "reactstrap";
import StarRatingInput from 'react-star-ratings';
import { applicationCreateRequest, applicationGetRequest, applicationUpdateRequest } from "store/applications/action";
import { propertyGetRequest } from 'store/properties/action';
import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import Select from "react-select";
import { Action } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SimpleBar from "simplebar-react";
import { ApplicationState } from "store";
import { applicationsCommissions } from "store/channels/action";
import { recommendApplicationList } from "store/recommendation/action";
import { useParams } from "react-router";
import { getCreateStatus } from "helpers/workflowStatusHelper";
import { isJson } from "utils";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";

const STARCOLORS = ["#ff4545", "#ff4545", "#ffa534", "#ffe234", "#b7dd29", "#57e32c"]

const PROPERTY_ID = '04f3764a-a37f-4634-970d-27e845bfca60';

// Utility function to check if a string is a valid JSON and parse it
const parseJson = (str: string) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return null;
    }
};

const ChannelWiseDetailsAddEntry = ({ props, show, onCloseClick, dataFields, record,studentId , fetchAppliedCourses}: any) => {
    const {id} = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const channelList = useSelector((state: ApplicationState) => state.channels.applicationsChannel);
    const property = useSelector((state: ApplicationState) => state.properties.property);

    const [formMetaData, setFormMetaData] = useState<any>([]);    
    const [formdata, setformdata] = useState<any>({});
    const [financeList, setfinanceList]= useState<any>({});
    const [processFlows, setProcessFlows] = useState<any[]>([]);
    const [selectedProcessFlow, setSelectedProcessFlow] = useState<any>(null);
    const [selectedChannel, setSelectedChannel] = useState<any>(null);
    const [selectedYears, setSelectedYears] = useState<any>(null)
    const [selectedMonths, setSelectedMonths] = useState<any>(null)
    // Fetch the property data when the component mounts
    useEffect(() => {
        dispatch(propertyGetRequest(PROPERTY_ID));
    }, [dispatch]);

    // Parse and set lifecycle and statuses data when property data is available
    useEffect(() => {
        if (property?.valuesJson) {
            const parsedData = parseJson(property.valuesJson);
            const processFlows = parsedData?.process_flows || [];
            setProcessFlows(processFlows);
        }
    }, [property]);
    
    const CreateApplication = () => {
        const { status } = getCreateStatus(selectedProcessFlow);
        let formValues= {channels: selectedChannel , process_flow: selectedProcessFlow.value};
        const handleSuccess = (body: any) => {
            fetchAppliedCourses()
            dispatch(recommendApplicationList(id))
            onCloseClick()            
        }
        const handleError = () => {

        }
        dispatch(applicationUpdateRequest(record.id, formValues, handleSuccess, () => { }))
    }
    
    useEffect(() => {
        let newstatusMetaData: any = []
        dataFields && dataFields.map((field: any) => {
            let item = field.valuesJson  && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
            if(item?.quickCreate) return newstatusMetaData.push(field)
        })
        let addEntryFields = newstatusMetaData.filter((obj: any) => JSON.parse(obj.valuesJson).key !== 'student' && JSON.parse(obj.valuesJson).key !== 'course_name');
        setFormMetaData(addEntryFields)
    }, [dataFields])

    useEffect(() => {
        let prefilleddata: any = []
       if(record && record.length) {
        prefilleddata = {
            student: studentId,
            course_name: record.id
        }
    }
        // let addEntryFields = newstatusMetaData.filter((obj: any) => JSON.parse(obj.valuesJson).key !== 'student');
        setformdata(prefilleddata)
    }, [record])

    useEffect(() => {     
    }, [record.id])

    useEffect(() => {
        const parsedData: any = channelList && channelList.length ? channelList.map((item: any) => {
            const valuesJson = item.valuesJson ? item.valuesJson : {};
            let values = { ...valuesJson, channel_id: item.id, createdAt: item.createdAt,averageRating: item.averageRating, commission: item.commission,commissionPercentage: item.commissionPercentage, taxIncluded: item.taxIncluded, ...item }
            return values
        }) : []
        let channels = parsedData && parsedData.length && parsedData.reverse() 
        setfinanceList(channels)
    }, [channelList])
    const onClose = () => {
        setSelectedChannel(null)
        setSelectedProcessFlow(null)
        onCloseClick(!show)
    }

    
    const months = [
        { label: 'January', value: 'january', in_forms: true },
        { label: 'February', value: 'february', in_forms: true  },
        { label: 'March', value: 'march', in_forms: true  },
        { label: 'April', value: 'april', in_forms: true  },
        { label: 'May', value: 'may', in_forms: true  },
        { label: 'June', value: 'june', in_forms: true  },
        { label: 'July', value: 'july', in_forms: true  },
        { label: 'August', value: 'august', in_forms: true  },
        { label: 'September', value: 'september', in_forms: true  },
        { label: 'October', value: 'october', in_forms: true  },
        { label: 'November', value: 'november', in_forms: true  },
        { label: 'December', value: 'december', in_forms: true  },
    ];

    const years = [
        { label: '2024', value: 2024 , in_forms: true },
        { label: '2025', value: 2025 , in_forms: true },
        { label: '2026', value: 2026 , in_forms: true },
        { label: '2027', value: 2027 , in_forms: true },
        { label: '2028', value: 2028 , in_forms: true },
        { label: '2029', value: 2029 , in_forms: true },
        { label: '2030', value: 2030 , in_forms: true },
        { label: '2031', value: 2031 , in_forms: true },
        { label: '2032', value: 2032 , in_forms: true },
        { label: '2033', value: 2033 , in_forms: true },
        { label: '2034', value: 2034 , in_forms: true },
        { label: '2035', value: 2035 , in_forms: true },
        { label: '2036', value: 2036 , in_forms: true },
        { label: '2037', value: 2037 , in_forms: true },
        { label: '2038', value: 2038 , in_forms: true },
        { label: '2039', value: 2039 , in_forms: true },
        { label: '2040', value: 2040 , in_forms: true },
    ]

    useEffect(() => {
        if(record?.intake) {
            console.log("record?.intake", record?.intake, "record", record)
            let intake = record?.intake?.includes("_") ? record?.intake.split("_") : record?.intake.split(" ");
            console.log("intake", intake);
            let month = months?.find((item: any) => item.value === intake[0])
            setSelectedMonths(month)
            let year = years?.find((item: any) => item.value == intake[1])
            setSelectedMonths(month)
            setSelectedYears(year)
        }
    }, [record])

    
    useEffect(() => {        
        if(selectedMonths?.value && selectedYears?.value && record.intake !== `${selectedMonths?.value}_${selectedYears?.value}`) {
            let formvalues  = {intake : `${selectedMonths?.value}_${selectedYears?.value}`}
            const handleSuccess = () => {
                dispatch(applicationGetRequest(record.id))
                dispatch(applicationsCommissions(record.id))
                dispatch(applicationGetRequest(record.id))
            }
            const handleFailure = () => {

            }
            dispatch(applicationUpdateRequest(record.id, formvalues, handleSuccess, handleFailure))
        }
    }, [selectedMonths?.value, selectedYears?.value])

   

    return (
          <Offcanvas
          direction="end"
          isOpen={show}
          id="offcanvasExample"
          toggle={onClose}
          backdrop={false}
      >
          <OffcanvasHeader className="bg-light" toggle={() => { onClose() }}>
              {props.t("student.create_application")}
          </OffcanvasHeader>
          <OffcanvasBody>
              <Card>
                <CardBody>
                    <div className="d-flex flex-column gap-2">
                        <div className="d-flex flex-column gap-1">
                                <FormBuilder                             
                                placeholder={props.t('properties.process_flow.application.select_process_flow_placeholder')}
                                onChange={(option : any) => setSelectedProcessFlow(option)}
                                options={processFlows}
                                label={"Select Process Flow"}
                                type={"select"}
                                value={selectedProcessFlow}
                                className="w-100 h-100 text-start"
                            />
                        </div>
                        <div>
                            <Label className='fw-semibold fs-16 text-primary'>Select Intake:</Label>
                            <Row>
                                <Col xl={6} md={6} lg={6}>
                                    <Select
                                        placeholder="Select..."
                                        className="basic-single "
                                        style={{height: 35}}
                                        classNamePrefix="select"
                                        isClearable
                                        isSearchable
                                        name="fields"
                                        options={months}
                                        closeOnSelect={true}
                                        value={selectedMonths}
                                        onChange={setSelectedMonths}
                                        menuPosition="fixed"
                                    />
                                </Col>
                                <Col xl={6} md={6} lg={6}>          
                                    <Select
                                        placeholder="Select..."
                                        className="basic-single "
                                        style={{height: 35}}
                                        classNamePrefix="select"
                                        isClearable
                                        isSearchable
                                        name="fields"
                                        options={years}
                                        value={selectedYears}
                                        onChange={setSelectedYears}
                                        menuPosition="fixed"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="d-flex flex-column gap-1 pt-2">
                            {selectedYears?.value && selectedMonths?.value ? 
                                <div>
                                    <Label>Select Channel Partner</Label>
                                    <ListGroup className="mb-1">
                                        {
                                        financeList && financeList.length ? financeList.map((item: any) => {
                                            return <ListGroupItem>
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex justify-content-between w-100">
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-check mb-2">
                                                            <Input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="flexRadioDefault"
                                                                onChange={() => setSelectedChannel(item)}
                                                            />
                                                        </div>
                                                        <div className="flex-shrink-0 avatar-xs">
                                                            <div className="avatar-title bg-danger-subtle text-danger rounded">
                                                                <i className="ri-community-fill"></i>
                                                            </div>
                                                        </div>
                                                        <div className="ms-2">
                                                            <h6 className="d-flex fs-14 mb-0 flex-wrap">{item.name}</h6>
                                                            <StarRatingInput
                                                                rating={parseFloat(item.averageRating) || 0}
                                                                starRatedColor={STARCOLORS[parseFloat(item.averageRating) || 0]}
                                                                numberOfStars={5}
                                                                name='rating'
                                                                starDimension="10"
                                                                starSpacing="2px"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Badge>£{item.commission}</Badge>
                                                    </div>
                                                </div>
                                            </div>
                                            </ListGroupItem>
                                    }) : <div>No Data to show for Channels</div>}                          
                                    </ListGroup>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </CardBody>
              </Card>
            </OffcanvasBody>
            <div className="offcanvas-footer bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                <Button
                    color="light"
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => CreateApplication()}
                    disabled={!selectedChannel || !selectedProcessFlow}
                >
                    Create
                </Button>
            </div>
      </Offcanvas>
    );
};

export default ChannelWiseDetailsAddEntry;