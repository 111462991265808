import React, { useEffect, useState } from 'react';
import { Button, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Section from 'Components/Common/Details/Section';
import Finance from 'pages/ChannelDetails/Finanace/Finance';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import StudentList from './StudentsList'
import { useParams } from 'react-router-dom';
import { ResetInvoceData } from 'store/auth/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { channelGetRequest, channelMetadataGetRequest, channelUpdateRequest, invoicesStudentsList } from 'store/channels/action';
import { isJson } from 'utils';
import { applicationsListGetRequest, applicationUpdateRequest } from 'store/applications/action';
import Contracts from './Contract/Contracts';

const Channel = (props: any) => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const ChannelDetail = useSelector((state: ApplicationState) => state.channels.details)
    const students = useSelector((state: ApplicationState) => state.channels.invoicesstudents)
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    // const students = useSelector((state: ApplicationState) => state.applications.list)
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const [isStudentListopen, setIsStudentListopen] = useState(false);
    const [mainList, setMainList] = useState<any>([]);
    const [selectedlist, setSelectedList] = useState<any>([]);
    const [disabled, setdisabled] = useState<any>(true)
    const [create, setCreate] = useState<boolean>(false);
    const [updatedRow, setUpdatedRow] = useState<any>({});

    const tabList = [
        {
        title: 'Finance',
        component: Finance
    },
    {
        title: 'Contracts',
        component: Contracts
    }
]

    const handleselect = (obj: any, value: boolean) => {
        if (value === true) {
            let newList = selectedlist
            newList.push(obj)
            setSelectedList(newList)
            setdisabled(!newList.length)
        }
        else {
            let newList = selectedlist.filter((item: any) => item.id !== obj.id);
            setSelectedList(newList)
            setdisabled(!newList.length)
        }

    }


    const onUpdate = (id: any, keyValue: any) => {
        console.log("keyValue", keyValue)
        const handleSuccess = (body: any) => {            
            dispatch(invoicesStudentsList(id))
        } 
        dispatch(applicationUpdateRequest(id, keyValue, handleSuccess, () => { }))
        
    }

    const handleRuleSelect = (obj: any, option: any) => {   
        console.log("obj", obj, "option", option)     
        let newList = mainList;
        let index = newList?.length ? newList.findIndex((item: any) => item.id === obj.id) : -1
        if(index > -1) { 
            newList[index] = {...newList[index], applied_ruleId: option?.value} 
            setMainList(newList)
            onUpdate(newList[index]?.id, {applied_ruleId: option?.value})
            setUpdatedRow(newList[index])
        }
    }

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setCreate(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.invoices;
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const createStudents = () => {
        dispatch(ResetInvoceData())
    }

    useEffect(() => {
        let filters = {
            "filters": [
                {
                    "quick": [
                        {
                            "key": "channel_name",
                            "keyLabel": "Channels",
                            "condition": "IN",
                            "conditionLabel": "is any of",
                            "values": [
                                id
                            ],
                            "valuesLabel": [
                                // {
                                //     "label": "Crizac",
                                //     "value": id,
                                //     "valuesJson": {
                                //         "city": "Kolkata",
                                //         "name": "Crizac",
                                //         "email": "info@crizac.com",
                                //         "state": "West Bengal",
                                //         "address": "Wing A, 3rd Floor, Constantia Bldg., 11 DR. U.N. Brahmachari Street, Shakespeare Sarani,\nKolkata - 700017, India",
                                //         "phone_number": "(033) 3544-1515",
                                //         "channel_short_name": "GA"
                                //     }
                                // }
                            ],
                            "property": {
                                "label": "Channels",
                                "value": "channel_name",
                                "type": "datasetselect",
                                "key": "channel_name",
                                "quick": true,
                                "valueJson": {
                                    "id": "55a07eb2-8048-4852-bac6-f41abd1b67a7",
                                    "key": "channel_name",
                                    "data": {
                                        "url": "",
                                        "json": "",
                                        "custom": "",
                                        "values": [
                                            {
                                                "label": "",
                                                "value": ""
                                            }
                                        ],
                                        "resource": ""
                                    },
                                    "type": "datasetselect",
                                    "input": true,
                                    "label": "Channels",
                                    "limit": 100,
                                    "model": "channels",
                                    "rules": {
                                        "filter": true,
                                        "unique": false,
                                        "maxLimit": {
                                            "value": 30,
                                            "restrictmaxLimit": false
                                        },
                                        "minLimit": {
                                            "value": 1,
                                            "restrictminLimit": false
                                        },
                                        "required": false,
                                        "show_informs": false,
                                        "restrictalpha": false,
                                        "restrictspecialcharacter": false
                                    },
                                    "addons": [],
                                    "filter": true,
                                    "hidden": false,
                                    "idPath": "id",
                                    "prefix": "",
                                    "suffix": "",
                                    "unique": false,
                                    "values": [
                                        {
                                            "id": 1,
                                            "label": "",
                                            "value": "",
                                            "in_forms": true
                                        }
                                    ],
                                    "widget": "choicesjs",
                                    "dataSrc": "values",
                                    "dbIndex": false,
                                    "groupId": "fac5bc52-bef1-4872-8f2b-ca6e34680138",
                                    "overlay": {
                                        "top": "",
                                        "left": "",
                                        "style": "",
                                        "width": "",
                                        "height": ""
                                    },
                                    "tooltip": "",
                                    "disabled": false,
                                    "lazyLoad": true,
                                    "multiple": false,
                                    "redrawOn": "",
                                    "tabindex": "",
                                    "template": "<span>{{ item.label }}</span>",
                                    "validate": {
                                        "custom": "",
                                        "unique": false,
                                        "multiple": false,
                                        "required": false,
                                        "customPrivate": false,
                                        "onlyAvailableItems": false,
                                        "strictDateValidation": false
                                    },
                                    "autofocus": false,
                                    "createdAt": "2024-09-21T03:27:27Z",
                                    "createdBy": {
                                        "id": "63bae413-6d53-4697-a700-9faff4b5337c",
                                        "name": "Zilter",
                                        "role": null,
                                        "email": "anirudha.skulkarni@gmail.com",
                                        "subAgent": null,
                                        "tenantId": null,
                                        "createdAt": null,
                                        "updatedAt": null,
                                        "phoneNumber": "+919087654321",
                                        "currentStage": null,
                                        "confirmationStatus": null
                                    },
                                    "deletedAt": null,
                                    "encrypted": false,
                                    "hideLabel": false,
                                    "indexeddb": {
                                        "filter": {}
                                    },
                                    "minSearch": 0,
                                    "modalEdit": false,
                                    "protected": false,
                                    "refreshOn": "",
                                    "tableView": true,
                                    "attributes": {},
                                    "errorLabel": "",
                                    "objectType": "applications",
                                    "persistent": true,
                                    "properties": {},
                                    "validateOn": "change",
                                    "clearOnHide": true,
                                    "conditional": {
                                        "eq": "",
                                        "show": null,
                                        "when": null,
                                        "highValue": null
                                    },
                                    "customClass": "",
                                    "description": "",
                                    "fuseOptions": {
                                        "include": "score",
                                        "threshold": 0.3
                                    },
                                    "ignoreCache": false,
                                    "keyModified": true,
                                    "optionLabel": "name",
                                    "placeholder": "Select Channel Name",
                                    "quickCreate": false,
                                    "searchField": "",
                                    "tablecolumn": true,
                                    "authenticate": false,
                                    "defaultValue": null,
                                    "selectFields": "",
                                    "customOptions": {},
                                    "dataGridLabel": false,
                                    "labelPosition": "top",
                                    "readOnlyValue": false,
                                    "searchEnabled": true,
                                    "showCharCount": false,
                                    "showWordCount": false,
                                    "uniqueOptions": false,
                                    "valueProperty": "",
                                    "calculateValue": "",
                                    "clearOnRefresh": false,
                                    "searchDebounce": 0.3,
                                    "useExactSearch": false,
                                    "calculateServer": false,
                                    "permissionsJson": {},
                                    "selectThreshold": 0.3,
                                    "allowMultipleMasks": false,
                                    "customDefaultValue": "",
                                    "allowCalculateOverride": false
                                }
                            },
                            "quick": true
                        }
                    ],
                    "advance": [],
                    "search": null
                }
            ],
            "sorts": [
                {
                    "field": "createdAt",
                    "order": "desc"
                }
            ]
        }
        dispatch(applicationsListGetRequest(filters,0,100))
        dispatch(invoicesStudentsList(id))

    },[]) 

    useEffect(() => {
        const parsedData: any = students && students.length ? students.map((item: any) => {
            const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson): item.valuesJson;
            let values = { ...valuesJson, id: item.id, createdAt: item.createdAt }
            return values
        }) : []
        let studentsList = parsedData && parsedData.length && parsedData.reverse()
        setMainList(studentsList)
    }, [students])

    const createInvoice = () => {
        if (create) return <Button
            onClick={() => { setIsStudentListopen(true); createStudents() }}
            color="primary"
            size='sm'
            className="btn-label py-2">
            <i className="ri-article-line label-icon align-middle fs-16 me-2"></i>
            {props.t("channel.create_invoice")}
        </Button>
        else return <Button
            color="primary"
            size='sm'
            disabled
            className="btn-label py-2">
            <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
            {props.t("channel.create_invoice")}
        </Button>
    }
    
    return (
        <React.Fragment>
            <StudentList
                show={isStudentListopen}
                onCloseClick={() => setIsStudentListopen(false)}
                mainList={mainList}
                selectedlist={selectedlist}
                setSelectedList={setSelectedList}
                handleselect={handleselect}
                ChannelDetail={ChannelDetail}
                disabled={disabled}
                handleRuleSelect={handleRuleSelect}
                updatedRow={updatedRow}
            />
            <div className="page-content">
                <Container fluid>
                    <Section
                        detailsGetAction={channelGetRequest}
                        metaDatagetRequest={channelMetadataGetRequest}
                        updateAction={channelUpdateRequest}
                        model={"channels"}
                        reducerState={"channels"}
                        translater={"channel"}
                        label={"Channels"}
                        tabList={tabList}
                        createInvoice={createInvoice}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Channel);