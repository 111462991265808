import { Reducer } from "redux";
import { CurrencyActionTypes, CurrencyState } from "./types";


export const initialState: CurrencyState =
{

    conversion: [],
    currency: null,
    loading: false,
    updateCurrency: false,
    updatedCurrency: null
}
const reducer: Reducer<CurrencyState> = (state: any = initialState, action: any) => {
    switch (action.type) {

        case CurrencyActionTypes.GET_CURRENCY_REQUEST: {
            return { ...state, loading: true };
        }
        case CurrencyActionTypes.GET_CURRENCY_SUCCESS: {
            return {
                ...state,
                loading: false,
                conversion: action.payload,
                updateCurrency: false
            };
        }
        case CurrencyActionTypes.GET_CURRENCY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case CurrencyActionTypes.GET_CURRENCIES_REQUEST: {
            return { ...state, loading: true };
        }
        case CurrencyActionTypes.GET_CURRENCIES_SUCCESS: {
            const page = action.payload.number || 1; // Set page to 1 if undefined
            const total = action.payload.totalPages;
            return {
                ...state,
                loading: false,
                currencies: action.payload.content,
            };
        }
        case CurrencyActionTypes.UPDATE_CURRENCY_SUCCESS: {
            const defaultCurrencyData = {
                selectedCurrency: 'GBP', // Default to USD if no stored value
                selectedCurrencySymbol: '£', // Default to $ if no stored value
                countryCode: 'gb' // Default to 'us' if no stored value
            };

            const data = action.payload
            return {
                updatedCurrency: data ? data : defaultCurrencyData
            };
        }
        case CurrencyActionTypes.UPDATE_CURRENCY_ERROR: {
            const data = action.payload
            return {
                error: 'Error while updating currency in the store',
            };
        }
        case CurrencyActionTypes.GET_CURRENCIES_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        default:
            return state; // Return the current state for unrecognized actions

    }
};

export { reducer as CurrencyReducer };
