import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Input, Label, Row, Table, Modal, ModalHeader, ModalBody, ButtonGroup } from "reactstrap";
import logo from "../../../../assets/images/dtsimg/svg/zilter_logo_white.svg";
import currency from "../../../../common/currency";
import moment from "moment";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import Flatpickr from "react-flatpickr";
import { SendEmail } from "store/applications/action";
import config from "../../../../config";
import { useSelector } from "react-redux";
import { applicationsMetadataGetRequest, applicationUpdateRequest, applicationGetRequest } from "store/applications/action";
import { ApplicationState } from "store";
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
import ReactDOMServer from "react-dom/server";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT
const DTS_URL = api.url

interface EmailBodyProps {
    studentName: string; // Name of the student
    reviewLink: string;  // Link for the student to review
}

const AgentChangeFormLetter = () => {
    const { tenant, id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const applicationsDetails: any = useSelector((state: ApplicationState) => state.applications.details);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const dataReceived = location.state;
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFomData] = useState<any>()
    const [value, setValue] = useState<any>(null)
    const [reason, setReason] = useState<string>("")
    const [currentAgent, setCurrentAgent] = useState<any>(null)
    const [agentAddress, setAgentAddress] = useState<any>(null)
    const [proposedAgent, setProposedAgent] = useState<any>(null)
    const [proposedAddress, setProposedAddress] = useState<any>(null)
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedSingle, setSelectedSingle] = useState<any>(null);
    // Toggle modal visibility
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    useEffect(() => {
        if (dataReceived) setFomData(dataReceived)
    }, [])

    console.log("applicationsDetails", applicationsDetails)

    const onUpdate = (formValues: any, field: string) => {
        let data;
        switch (field) {
            case "reason_for_change":
                setReason(formValues)
                data = { reason_for_taking_assistance: formValues };
                break;

            case "date_of_birth":
                data = { date_of_birth: formValues }; // Update the appropriate field
                break;

            case "country_of_nationality":
                data = { country_of_nationality: formValues }; // Update the appropriate field
                break;

            case "ucas_id":
                data = { ucas_id: formValues }; // Update the appropriate field
                break;

            case "current_agent":
                data = { current_agent: formValues }; // Update the appropriate field
                break;

            case "proposed_agent":
                data = { proposed_agent: formValues }; // Update the appropriate field
                break;

            case "current_agent_address":
                data = { current_agent_address: formValues }; // Update the appropriate field
                break;
            case "proposed_agent_address":
                data = { proposed_agent_address: formValues }; // Update the appropriate field
                break;
            default:
                console.error("Invalid field");
                return; // Exit if the field is not recognized
        }

        const handleSuccess = (body: any) => {
            setValue(null)
            const message = () =>
                toast(`Application updated Successfully`, {
                    position: "top-center",
                    hideProgressBar: true,
                    className: "bg-success text-white"
                });
            message();
            dispatch(applicationGetRequest(dataReceived.details.id)); // Ensure `id` is properly defined in scope
        };
        if (data) {
            dispatch(
                applicationUpdateRequest(dataReceived.details.id, data, handleSuccess)
            );
        } else {
            console.error("No data to update");
        }
    };

    const EmailBody: React.FC<EmailBodyProps> = ({ studentName, reviewLink }) => (
        <div>
            <p>Dear {studentName || "Student"},</p>
            <p>I hope this email finds you well.</p>
            <p>
                You have been provided a unique link to complete an important process.
                Kindly follow the steps below to review, submit, and complete your task:
            </p>
            <ol>
                <li>
                    <strong>Review and Verify Information:</strong> Ensure all the
                    details displayed are accurate and up-to-date.
                </li>
                <li>
                    <strong>Selfie Verification:</strong> You will be prompted to take a
                    selfie to verify your identity. Please follow the instructions
                    carefully.
                </li>
                <li>
                    <strong>Submit and Generate PDF:</strong> After completing the
                    review and verification, submit the information. A PDF containing
                    the completed details will be generated for your records.
                </li>
            </ol>
            <p>         <strong><a style={{ color: "blue" }} href={reviewLink}>Click here</a></strong>&nbsp;<span>to begin the process</span></p>
            <p>Thank you for your prompt attention to this matter.</p>

        </div>
    );


    const onSend = () => {
        const toemail =
            dataReceived?.details?.valuesJson?.student?.email || "";
        const studentName =
            dataReceived?.details?.valuesJson?.student?.name || "Student";
        const reviewLink = `${DTS_URL}/agent-letter/${dataReceived.tenantId}/${dataReceived.details.id}`;

        // Convert React component to static HTML
        const emailBodyHTML = ReactDOMServer.renderToStaticMarkup(
            <EmailBody studentName={studentName} reviewLink={reviewLink} />
        );
        let model = "applications"
        const data = {
            // parentId: emailinfo.id,
            studentId: dataReceived?.details?.valuesJson?.student?.id,
            applicationId: dataReceived?.details?.id,
            to: toemail,
            subject: "Action Required: Review, Submit, and Generate PDF with Selfie Verification",
            body: emailBodyHTML
            // bcc: emailinfo?.bcc ? [...emailinfo?.bcc?.split(",")] : null,
            // cc: emailinfo?.cc ? [...emailinfo?.cc?.split(",")] : null
        }
        const handleSuccess = () => {
            // handleGetList()
            // setInnitial()
            // setEmailData({to: to,applicationId: applicationId, studentId: studentId })
            // setEmailOpen(false)
        }
        const handleFailure = () => { }
        dispatch(SendEmail(data, handleSuccess, handleFailure))
    }

    const SingleOptions = currency.map(country => ({
        value: country.name, // Use the country's name as the value
        label: country.name, // Use the country's name as the label
    }));

    const handleDateChange = (selectedDates: string | any[]) => {
        onUpdate(selectedDates[0].toISOString(), "date_of_birth")
        console.log("selected date", (selectedDates[0].toISOString()))
        if (selectedDates.length > 0) {
            setSelectedDate(selectedDates[0]); // Set the first selected date to state
        }
    };

    function handleSelectSingle(selectedSingle: any) {
        console.log("counntry", selectedSingle.value)
        onUpdate(selectedSingle.value, "country_of_nationality")
        setSelectedSingle(selectedSingle);
    }

    const goBack = () => {
        navigate(-1);
    };
    return (
        <>
            <ToastContainer />
            <div id="authorization-request-form">
                <div className="bg-dark">
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-1 mb-4 text-white-50">
                                <div>
                                    <Label aria-disabled to="/#" className="d-inline-block auth-logo">
                                        <img src={logo} alt="" height="50" />
                                    </Label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Button color="primary" onClick={goBack} className="mt-4 ms-3">
                        Back
                    </Button>
                </div>
                <div className="p-3 mt-1 d-flex justify-content-center">
                    <Col xl={8}>
                        <div>
                            <Row>
                                <Label className="text-center mt-3 mb-5 fs-20">AGENT REQUEST FORM</Label>
                            </Row>
                            <div className="mx-5 mb-2">
                                <Table className="table-bordered align-middle mb-0">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>First Name</td>
                                            <td style={{ width: "60%" }}>{applicationsDetails?.valuesJson?.student?.first_name ?
                                                applicationsDetails.valuesJson?.student?.first_name
                                                :
                                                <div className="d-flex border rounded-3 align-items-center">
                                                    <Input className="form-control border-0 ps-2" value={""} onChange={(e: any) => setValue(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    <ButtonGroup>
                                                        {/* <Button onClick={(e) => { e.preventDefault(); setHover(false); onChange(resetValue) }} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button> */}
                                                        <Button onClick={(e) => { onUpdate(value, "first_name") }} disabled={!value} color="primary" className="btn-icon"> <i className="ri-check-fill" /> </Button>
                                                    </ButtonGroup>
                                                </div>
                                            }</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Last Name</td>
                                            <td style={{ width: "60%" }}>{applicationsDetails?.valuesJson?.student?.last_name ?
                                                applicationsDetails?.valuesJson?.student?.last_name
                                                :
                                                <div className="d-flex border rounded-3 align-items-center">
                                                    <Input className="form-control border-0 ps-2" value={""} onChange={(e: any) => setValue(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    <ButtonGroup>
                                                        {/* <Button onClick={(e) => { e.preventDefault(); setHover(false); onChange(resetValue) }} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button> */}
                                                        <Button onClick={(e) => { onUpdate(value, "last_name") }} disabled={!value} color="primary" className="btn-icon"> <i className="ri-check-fill" /> </Button>
                                                    </ButtonGroup>
                                                </div>}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Date of Birth:</td>
                                            <td style={{ width: "60%" }}>{applicationsDetails?.valuesJson?.date_of_birth ? moment(applicationsDetails?.valuesJson?.date_of_birth).format("DD/MMM/YYYY") :
                                                <div className="input-group">
                                                    <Flatpickr
                                                        placeholder="Select birth date"
                                                        className="form-control"
                                                        options={{
                                                            dateFormat: "d M, Y",
                                                        }}
                                                        onChange={handleDateChange}
                                                    />
                                                    <span className="input-group-text p-2 py-0">
                                                        <i className="ri-calendar-line"></i>
                                                    </span>
                                                </div>}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Country of Nationality:</td>
                                            <td style={{ width: "60%" }}>{applicationsDetails?.valuesJson?.country_of_nationality ?
                                                applicationsDetails?.valuesJson?.country_of_nationality :
                                                <Select
                                                    value={selectedSingle}
                                                    onChange={(selectedSingle: any) => {
                                                        handleSelectSingle(selectedSingle);
                                                    }}
                                                    options={SingleOptions}
                                                />}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Your Current Agent or ‘Applied
                                                Direct’</td>
                                            <td style={{ width: "60%" }}>
                                                {applicationsDetails?.valuesJson?.current_agent ? applicationsDetails?.valuesJson?.current_agent :
                                                    <div className="d-flex border rounded-3 align-items-center">
                                                        <Input className="form-control border-0 ps-2" value={currentAgent} onChange={(e: any) => setCurrentAgent(e.target.value)}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                        <ButtonGroup>
                                                            {/* <Button onClick={(e) => { e.preventDefault(); setHover(false); onChange(resetValue) }} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button> */}
                                                            <Button onClick={(e) => { onUpdate(currentAgent, "current_agent") }} disabled={!currentAgent} color="primary" className="btn-icon"> <i className="ri-check-fill" /> </Button>
                                                        </ButtonGroup>
                                                    </div>}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Address of Your Current Agent:</td>
                                            <td style={{ width: "60%" }}>{applicationsDetails?.valuesJson?.current_agent_address ? applicationsDetails?.valuesJson?.current_agent_address :
                                                <div className="d-flex border rounded-3 align-items-center">
                                                    <Input className="form-control border-0 ps-2" value={agentAddress} onChange={(e: any) => setAgentAddress(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    <ButtonGroup>
                                                        {/* <Button onClick={(e) => { e.preventDefault(); setHover(false); onChange(resetValue) }} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button> */}
                                                        <Button onClick={(e) => { onUpdate(agentAddress, "current_agent_address") }} disabled={!agentAddress} color="primary" className="btn-icon"> <i className="ri-check-fill" /> </Button>
                                                    </ButtonGroup>
                                                </div>}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Your Proposed Agent:</td>
                                            <td style={{ width: "60%" }}>{applicationsDetails?.valuesJson?.proposed_agent ? applicationsDetails?.valuesJson?.proposed_agent :
                                                <div className="d-flex border rounded-3 align-items-center">
                                                    <Input className="form-control border-0 ps-2" value={proposedAgent} onChange={(e: any) => setProposedAgent(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    <ButtonGroup>
                                                        {/* <Button onClick={(e) => { e.preventDefault(); setHover(false); onChange(resetValue) }} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button> */}
                                                        <Button onClick={(e) => { onUpdate(proposedAgent, "proposed_agent") }} disabled={!proposedAgent} color="primary" className="btn-icon"> <i className="ri-check-fill" /> </Button>
                                                    </ButtonGroup>
                                                </div>}</td>

                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Address of Your Proposed Agent:</td>
                                            <td style={{ width: "60%" }}>{applicationsDetails?.valuesJson?.proposed_agent_address ? applicationsDetails?.valuesJson?.current_agent_address :
                                                <div className="d-flex border rounded-3 align-items-center">
                                                    <Input className="form-control border-0 ps-2" value={proposedAddress} onChange={(e: any) => setProposedAddress(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                    <ButtonGroup>
                                                        {/* <Button onClick={(e) => { e.preventDefault(); setHover(false); onChange(resetValue) }} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button> */}
                                                        <Button onClick={(e) => { onUpdate(proposedAddress, "proposed_agent_address") }} disabled={!proposedAddress} color="primary" className="btn-icon"> <i className="ri-check-fill" /> </Button>
                                                    </ButtonGroup>
                                                </div>}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: "40%", whiteSpace: "nowrap" }}>Reason(s) for change of Agent:</td>
                                            <td style={{ width: "60%" }}>
                                                {applicationsDetails?.valuesJson?.reason_for_taking_assistance ? applicationsDetails?.valuesJson?.reason_for_taking_assistance :
                                                    <div className="d-flex border rounded-3 align-items-center">

                                                        <Input className="form-control border-0 ps-2" value={reason} onChange={(e: any) => setReason(e.target.value)}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                        <ButtonGroup>
                                                            {/* <Button onClick={(e) => { e.preventDefault(); setHover(false); onChange(resetValue) }} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button> */}
                                                            <Button onClick={(e) => { onUpdate(reason, "reason_for_change") }} disabled={!reason} color="primary" className="btn-icon"> <i className="ri-check-fill" /> </Button>
                                                        </ButtonGroup>
                                                    </div>
                                                }

                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <div className="mx-5">
                                <Row className="mt-3 mb-5">
                                    <Label className="mb-1 fs-14">
                                        {`Upon receipt of my completed form, I am aware and authorize the university to remove my current agent and change it in the student database to my proposed Agent (${applicationsDetails.valuesJson.channel_name?.name || "NA"}). Based on my previous Privacy Declaration, (as applicable) all future correspondence will be sent to ${applicationsDetails.valuesJson.channel_name?.name || "NA"}`}
                                    </Label>

                                </Row>
                                <div className="d-flex justify-content-between align-items-baseline">
                                    {/* <div>
                                        <Label>Signature: __________________________</Label>
                                    </div> */}
                                    <div>
                                        <Label>Date: {moment().format("MM/DD/YYYY")}</Label>
                                    </div>
                                    <div className="">
                                        <Button color="primary" onClick={onSend} className="btn btn-sm">
                                            Send to student
                                        </Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Col>
                </div>

            </div>

        </>
    );
};

export default AgentChangeFormLetter;
