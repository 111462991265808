import TableContainer from "pages/Properties/roles/TableComponent";
import { useEffect, useState } from "react";
import {  Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Modal, ModalBody,  ModalHeader } from "reactstrap";
import SimpleBar from "simplebar-react";

const ViewAll = ({ props, show, onCloseClick, viewlist, setisDeleteOpen, setIsEditOpen, setRecord, setViewname}: any) => {

    return (
        <Modal backdrop="static" isOpen={show} toggle={onCloseClick} centered={true} size="lg">
            <ModalHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={onCloseClick}>
                <span className='text-light'>{props.t("views.all_views")}</span>
            </ModalHeader>
            <ModalBody className='text-center p-5'>
            <div className="table-responsive">
                <SimpleBar autoHide={false} className="simplebar-track-dark pe-2" style={{ maxHeight: "500px" }}>
                    <table className="table align-middle position-relative table-nowrap">
                        <thead className="cursor-pointer table-active position-sticky">
                            <tr>
                                <th className="border-bottom-1 table-soft-primary  fw-bold">
                                    View Name
                                </th>
                                <th  className="border-bottom-1 table-soft-primary  fw-bold">
                                    Location
                                </th>
                                <th  className="border-bottom-1 table-soft-primary  fw-bold">
                                    Actions
                                </th>
                            </tr>
                        </thead>

                        <tbody id="task-list">
                            {viewlist?.length && viewlist.map((item: any, key: any) => (
                                <tr key={key} style={{ verticalAlign: 'middle', maxHeight: 50 }}>
                                    <td style={{ verticalAlign: 'middle' }} className='align-items-center justify-content-between'>
                                        <div>
                                            <div>
                                                {item.name}
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }} className='align-items-center justify-content-between'>
                                        <div>
                                            <div>
                                                {item.location}
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }} className='align-items-center justify-content-between'>
                                        <ul className="hstack gap-1">                                                    
                                                <li
                                                    onClick={() => {setIsEditOpen(true);setRecord(item);setViewname(item.name) }}
                                                    className="dropdown-item edit-item-btn fs-12"
                                                    title="Edit"
                                                >
                                                    <button className="btn btn-sm btn-soft-secondary remove-list"
                                                    >
                                                        <i className="ri-pencil-fill"></i>{" "}
                                                    </button>{" "}
                                                </li>
                                                <li
                                                    className="dropdown-item remove-item-btn fs-12"
                                                    title="Edit"
                                                    onClick={() => {setisDeleteOpen(true);setRecord(item); setViewname(item.name)}}
                                                >
                                                    <button className="btn btn-sm btn-soft-danger remove-list"
                                                    >
                                                        <i className="ri-delete-bin-fill"></i>
                                                    </button>  {" "}
                                                </li> 
                                        </ul>
                                    </td>
                                </tr>))}
                        </tbody>

                    </table>
                </SimpleBar>
            </div>

            </ModalBody>
        </Modal>
    ) as unknown as JSX.Element;
};

export default ViewAll;