import {
    Button,
    Card,
    CardBody
} from "reactstrap";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import avatar7 from "../../../../../assets/images/users/avatar-3.jpg";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { noteAddRequest } from "store/note/action";
import { notesListGetRequest } from "store/note/action";
import moment from "moment";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { toast } from "react-toastify";

const noteType = [
    {
        options: [
            { label: "Counselling Session Summary", value: "COUNSELLING SESSION SUMMARY" },
            { label: "Follow-up call", value: "FOLLOW-UP CALL" },
            { label: "Incoming Call", value: "INCOMING CALL" },
            { label: "Attempt to book", value: "ATTEMPT TO BOOK" },
            { label: "Proof of Funds Details", value: "PROOF OF FUNDS DETAILS" },
            { label: "Course Notes", value: "COURSE NOTES" },
            { label: "Finance Notes", value: "FINANCE NOTES" },
            { label: "Others", value: "OTHERS" }
        ],
    },
];

const Notes = (props: any) => {
    const { t, record, model } = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const notes = useSelector((state: ApplicationState) => state.note.notes);
    const [replyNoteId, setReplyNoteId] = useState<string | null>(null);
    const [replyNote, setReplyNote] = useState("")
    const [newNoteId, setNewNoteId] = useState<string | null>(null)
    const [selectedNoteType, setSelectedNoteType] = useState(null);
    const [studentId, setStudentId] = useState("")
    const [applicationId, setApplicationId] = useState("")
    const [isAddNoteButtonDisabled, setIsAddNoteButtonDisabled] = useState<boolean>(false);
    const [shortNoteValue, setShortNoteValue] = useState("");
    const [transcribing, setTranscribing] = useState("child")
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition({ transcribing: transcribing == "child" });

    const handleGetList = () => {
        if(model == "students") {
            if (record && record.id) {
                setStudentId(record.id)
                dispatch(notesListGetRequest(record.id));
            }
        }
        else {
            if (record && record.id) {
                setApplicationId(record.id)
                let studentsId = record && record?.student && record?.student.id ? record?.student.id : "";
                setStudentId(studentsId)
                dispatch(notesListGetRequest(studentsId, {applicationId: record.id}))
            }
        }     
    }

    useEffect(() => {
        if (record && record.id) {
            handleGetList();
        }
    }, [record])

    useEffect(() => {
        setReplyNote(transcript)
    }, [transcript])

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    const startListening = () => {
        setTranscribing("child");
        SpeechRecognition.startListening({ continuous: true });
    };

    const stopListening = () => {
        SpeechRecognition.stopListening();
    };

    const handleReplyNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReplyNote(event.target.value);
        setNewNoteId("");
        setReplyNoteId(replyNoteId);
    };

    const handleNoteTypeChange = (selectedOption: any) => {
        setSelectedNoteType(selectedOption);
        setShortNoteValue(selectedOption.label)
        // You can also update the formData here if needed
    };

    const handleReplyNote = () => {
        stopListening();
        const data =
        {
            studentId: newNoteId,
            applicationId: applicationId,
            shortNote: shortNoteValue,
            parentId: replyNoteId,
            note: replyNote
        }
        const handleSuccess = (body: any): void => {
            handleReset()
            handleGetList()

        };

        const handleError = (body: any): void => {
        };

        dispatch(noteAddRequest(data, handleSuccess, handleError));
    };

    
    const toggleReply = (noteId: string) => {
        if (replyNoteId === noteId) {
            setReplyNoteId(null);
        } else {
            setReplyNoteId(noteId);
        }
    };

    const handleReset = () => {
        setReplyNote("");
        setSelectedNoteType(null)
        resetTranscript();
        setShortNoteValue('')
    };

    const renderComments = (notes: any) => {
        return (
            <React.Fragment>
                {notes && notes.length ? notes.map((comment: any, key: number) => (
                    <div className="vstack" key={key}>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <img src={avatar7} alt="" className="avatar-xs rounded-circle" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                                <h5 className="hstack flex-wrap gap-2 fs-15 mb-1">
                                    <Link to="/pages-profile">{comment?.createdBy?.name}</Link>{" "}
                                        <div>
                                            <span className="badge rounded-pill bg-info-subtle text-info">{comment?.shortNote}</span>
                                        </div>
                                     </h5>                               
                            </div>                                                           
                        </div>
                        <div className="vstack gap-1"> 
                            <div className="hstack gap-2 justify-content-end">
                                <small className="text-muted">{moment(comment?.createdAt).format('MMMM DD, YYYY hh:mm A')}</small>
                            </div> 
                            <div className="ms-2">                            
                                <p className="text-muted mb-0">{comment?.detailedNote}</p>
                            </div>
                        </div>
                        <div>
                            {replyNoteId === comment.id ? (
                                <div>
                                    <textarea className="form-control bg-light border-light" value={replyNote} onChange={handleReplyNoteChange} rows={3} placeholder={t("applications.reply_to_this_note")}></textarea>
                                    <div className="hstack justify-content-end gap-1">
                                        <div  style={{minWidth: 150}}>                                            
                                            <Select
                                                placeholder="Select type"
                                                isLoading={false}
                                                options={noteType}
                                                value={selectedNoteType}
                                                onChange={handleNoteTypeChange}
                                                menuPlacement="auto" // You can also try "bottom" or "top"
                                                menuPosition="fixed"
                                                className={"form-select-sm"}
                                            />
                                        </div>
                                        {listening ? (
                                            <>
                                                <Button color="danger" size="sm" className="btn-icon" outline type="button" onClick={stopListening}> <i className="ri-mic-off-line"></i> </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button color="primary" size="sm" className="btn-icon" outline type="button" onClick={startListening}> <i className="ri-mic-line"></i> </Button>
                                            </>
                                        )}
                                        <Button color="success" size="sm" type="button"
                                        onClick={(e) => {
                                            handleReplyNote();
                                            e.preventDefault();
                                            toggleReply(comment.id);
                                        }}
                                            disabled={isAddNoteButtonDisabled || (!replyNote || !selectedNoteType) && (!transcript || !selectedNoteType)}
                                            >
                                                {t("applications.reply")}</Button>
                                         <Button color="danger" size="sm" type="button"
                                                onClick={() => {
                                                    handleReset()
                                                    toggleReply(comment.id)
                                                }} 
                                        >
                                            {t("applications.cancel")}</Button>
                                    </div>
                                </div>
                            ) :
                                <div className="mb-2 ms-2">
                                    <Link to="#" className="badge text-muted bg-light" onClick={() => {
                                        // e.preventDefault(); 
                                        toggleReply(comment.id)
                                        setReplyNoteId(comment.id)
                                    }}>
                                        <i className="mdi mdi-reply"></i> {t("applications.reply")}
                                    </Link>
                                </div>
                            }
                        </div>
                        {/* Render nested comments */}
                        {comment.replies && comment.replies.length > 0 && (
                            <div className="ms-5">
                                {renderComments(comment.replies)}
                            </div>
                        )}
                    </div>
                )) :  <h6 className="p-3">no previous Comments</h6>}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {/* Your existing JSX code */}
            <Card>
                <CardBody className="px-0">
                    <SimpleBar style={{ maxWidth: 500 }} className="">
                        {renderComments(notes)}
                    </SimpleBar>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(Notes);
