import React, { useCallback, useEffect, useState } from "react";
import { Button, Input } from 'reactstrap';
import conditionsJson from "./conditionsJson.json";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import { getOptions } from "store/auth/action";
import { ApplicationState } from "store";
import Flatpickr from "react-flatpickr";
import SelectTree from "Components/Common/SelectTree/SelectTree";

const animatedComponents = makeAnimated();

// Interface for Property
interface PropertyProps {
    id: number;
    key: string;
    label: string | Date;
    type: string;
    value: string;
    model: string;
    dataSource: any;
    optionLabel: string;
    values: any;
    options: any;
}

// Interface for Condition
interface ConditionProps {
    label: string;
    value: string;
}

// Interface for TransformedNode
interface TransformedNode {
    label: any;
    value: any;
    createdBy: any;
    createdAt: any;
    updatedAt: any;
    deletedAt: any;
    parentId: any;
    parent: any;
    children?: TransformedNode[]; // Mark children as optional
}

// Helper function to check if a string is valid JSON
const isJson = (str: string) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return [];
    }
};
const AddFilter = ({ filter, onFilterUpdated, activeFilters, toggleDefault }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const optionsObj = useSelector((state: ApplicationState) => state.auth.optionData);
    const optionsLoading = useSelector((state: ApplicationState) => state.auth.optionsLoading);

    // State Variables
    const [selectedProperty, setSelectedProperty] = useState<PropertyProps | null>(null);
    const [conditions, setConditions] = useState<ConditionProps[]>([]);
    const [selectedCondition, setSelectedCondition] = useState<ConditionProps | null>(null);
    const [options, setOptions] = useState<any>([]);
    const [values, setValues] = useState<PropertyProps[]>([]);
    const [value, setValue] = useState<Date | number | null>(null);
    const [highValue, setHighValue] = useState<Date | number | null>(null);

    // Clear filter selections
    const clearFilter = useCallback(() => {
        setSelectedProperty(null);
        setConditions([]);
        setSelectedCondition(null);
        resetValueStates();
    }, []);

    useEffect(() => {
        if(filter) {
            setSelectedProperty(filter)
            const conditionOptions = conditionsJson[filter.type as keyof typeof conditionsJson];
            setConditions(conditionOptions);
            if(filter?.condition) {
                setSelectedCondition({value: filter?.condition, label: filter?.conditionLabel})
            } else {
                if(conditionOptions) {
                    setConditions(conditionOptions);
                    setSelectedCondition(conditionOptions[0]); // Set the first condition by default
                }
            }
            if(filter?.valuesLabel) {
                setValues(filter?.valuesLabel);
            }
            if(filter?.value) {
                setValue(filter?.value);
            }
            if(filter?.highValue) {
                setHighValue(filter?.highValue);
            }
        } else {
            clearFilter()
        }
    }, [filter, clearFilter])

    // Update options based on selected property and options data from Redux
    useEffect(() => {
        if (selectedProperty && optionsObj?.length) {
            if(selectedProperty.type === 'treeselect') {
                const createdNodes: TransformedNode[] = optionsObj.map(transformTree);
                setOptions(createdNodes);
            } else {
                const newOptions = optionsObj.map((option: any) => {
                    const optionsJson = isJson(option.valuesJson);
                    return { label: optionsJson[selectedProperty?.optionLabel], value: option.id, valuesJson: optionsJson };
                });
                setOptions(newOptions);
            }
        }
    }, [optionsObj, selectedProperty]);

    // Function to find multiple nodes by a list of ids
    const transformTree = (node: any): TransformedNode => {
        // Parse the valuesJson field to extract the name
        const values = JSON.parse(node.valuesJson);
      
        // Create the base transformed node
        const transformedNode: TransformedNode = {
          label: values.name,
          value: node.id,
          createdBy: node.createdBy,
          createdAt: node.createdAt,
          updatedAt: node.updatedAt,
          deletedAt: node.deletedAt,
          parentId: node.parentId,
          parent: node.parent,
        };
      
        // Recursively transform children if they exist and are not empty
        if (node.children && node.children.length > 0) {
          transformedNode.children = node.children.map(transformTree);
        }
      
        return transformedNode;
    }

    // Reset value-related states
    const resetValueStates = () => {
        setValues([]);
        setValue(null);
        setHighValue(null);
    };

    // Dispatch option fetch action if a model is associated with the selected property
    const onFocus = () => {
        if (selectedProperty?.model) {
            dispatch(getOptions(selectedProperty?.model, {}));
        }
    };

    // Handle filter selection for submission
    const onFilterSelectClicked = () => {
        const { id, key, label: keyLabel } = selectedProperty || {};
        const { value: condition, label: conditionLabel } = selectedCondition || {};

        const newFilter = {
            id: id || ((activeFilters?.length + 1) || 1),
            key,
            keyLabel,
            condition,
            conditionLabel,
            values: values.map(({ value }) => value),
            valuesLabel: values,
            property: selectedProperty,
            value,
            highValue
        };

        onFilterUpdated(newFilter);
        toggleDefault('')
        clearFilter();
    };


    // Render input field based on property type
    const renderValueInput = () => {
        switch (selectedProperty?.type) {
            case 'datasetselect':
                return (
                    <Select
                        onFocus={onFocus}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        isClearable
                        isSearchable
                        name="values"
                        options={options}
                        onChange={setValues}
                        value={values}
                        loading={optionsLoading}
                    />
                );
            case 'select':
            case 'checkbox':
            case 'selectboxes':
                return (
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        isClearable
                        isSearchable
                        name="values"
                        options={selectedProperty?.options}
                        onChange={setValues}
                        value={values}
                    />
                );
            case 'textfield':
            case 'textarea':
            case 'email':
            case 'phone':
                return (
                    <CreatableSelect
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        isClearable
                        isSearchable
                        name="values"
                        options={[]}
                        onChange={(newValue: any) => setValues(newValue)}
                        value={values}
                    />
                );
            case 'datetime':
                if (selectedCondition?.value === 'BETWEEN') {
                    return (
                        <div className="d-flex flex-column gap-1">
                            <Flatpickr
                                className="form-control w-100 text-start"
                                value={value ? value : undefined}
                                options={{ maxDate: value ? value : undefined }}
                                onChange={([date]) => setValue(date)}
                            />
                            <span>and</span>
                            <Flatpickr
                                className="form-control w-100 text-start"
                                value={highValue ? highValue : undefined}
                                options={{ minDate: value ? value : undefined }}
                                onChange={([date]) => setHighValue(date)}
                            />
                        </div>
                    );
                } else {
                    return (
                        <Flatpickr
                            className="form-control w-100 h-100 text-start"
                            value={value ? value : undefined}
                            onChange={([date]) => setValue(date)}
                        />
                    );
                }
            case 'number':
                if (selectedCondition?.value === 'BETWEEN') {
                    return (
                        <div className="d-flex flex-column gap-1">
                            <Input
                                type="number"
                                name="label"
                                value={value ? value.toString() : undefined}
                                onChange={(e: any) => setValue(e.target.value)}
                                placeholder='Enter minimum value'
                            />
                            <span>and</span>
                            <Input
                                type="number"
                                name="label"
                                value={highValue ? highValue.toString() : undefined}
                                onChange={(e: any) => setHighValue(e.target.value)}
                                placeholder='Enter maximum value'
                            />
                        </div>
                    );
                } else {
                    return (
                        <Input
                            type="number"
                            name="label"
                            value={value ? value.toString() : undefined}
                            onChange={(e: any) => setValue(e.target.value)}
                            placeholder='Enter value'
                        />
                    );
                }
            case 'treeselect':
                return (
                    <SelectTree
                        setValues={(values: any) => setValues(values)}
                        onFocus={onFocus}
                        options={options}
                        values={values}
                        showValues={true}
                        optionsLoading={optionsLoading}
                    />
                );
            default:
                return null;
        }
    };
    return (
        <div className="d-flex flex-column gap-2 w-100">
            {selectedProperty && (
                <div className="d-flex flex-column gap-2">
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable
                        name="conditions"
                        options={conditions}
                        onChange={setSelectedCondition}
                        value={selectedCondition}
                    />
                    {renderValueInput()}
                </div>
            )}
            <div className='d-flex gap-2 justify-content-start w-100'>
                <Button
                    onClick={onFilterSelectClicked}
                    color="primary"
                    disabled={
                        !(
                            selectedCondition?.value === 'BETWEEN'
                            ? highValue && value && highValue >= value
                            : values?.length || value
                        )
                    }
                >
                    Apply
                </Button>
                <Button color="light"
                    onClick={() => toggleDefault('')}
                >
                    Cancel
                </Button>
            </div>
        </div>
    );
};

export default AddFilter;
