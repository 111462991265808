import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import conditionsJson from "../Common/Config/MainPanel/ControlPanel/Filter/conditionsJson.json";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { reportGenerateRequest } from "store/report/action";
import { Label, Button } from "reactstrap";
import { GeneratedReport } from "store/report/types";
import ChartLoader from "../Common/ChartLoader";

interface ReportProps {
    config: any;
    onDetailsReport: (data: any) => void;
}

const AreaReport: React.FC<ReportProps> = ({ config, onDetailsReport }) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [option, setOption]: any = useState(null);
    const [report, setReport] = useState<GeneratedReport | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    
    useEffect(() => {
        if(config) {
            generateReport();
        }
    }, [config])

    const generateReport = () => {
        setLoading(true);
        setError(false);
        const handleSuccess = (response: GeneratedReport) => {
            setLoading(false);
            setError(false);
            setReport(response);
        }
        const handleError = () => {
            setLoading(false);
            setError(true);
        }
        dispatch(reportGenerateRequest(config, handleSuccess, handleError))
    }

    useEffect(() => {
        if (report?.xAxis) {
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    type: 'scroll',
                    orient: 'horizontal',
                    top: 0,
                    left: 'center',
                    itemWidth: 14,
                    itemHeight: 14,
                    textStyle: {
                        fontSize: 12,
                    },
                    pageIconSize: 12,
                    pageTextStyle: {
                        fontSize: 12,
                    }
                },
                xAxis: report.xAxis
                    ? [{ ...report.xAxis, axisLabel: { interval: 0, rotate: 30 } }]
                    : [],
                yAxis: report.yAxis ? [report.yAxis] : [],
                series: report.series || []
            };
            setOption(option);
        }
    }, [config, report]);
    
    const onChartClick = (params: any) => {
        const xAxis = config?.x_axis;
        const { key, label: keyLabel, data_source, json_field } = xAxis;
        const conditionOptions = conditionsJson[xAxis.type as keyof typeof conditionsJson];
        const { value: condition, label: conditionLabel } = conditionOptions[0];
        const filter = {
            key,
            keyLabel,
            condition,
            conditionLabel,
            values: [params?.name],
            valuesLabel: [{label: params?.name, value: params?.name}],
            property: xAxis,
            json_field,
            data_source,
            detail_field: true
        };
        onDetailsReport(filter);
    };

    const onEvents = {
        'click': onChartClick,
    };

    if(loading) {
        return (
            <ChartLoader />
        )
    }

    if(error) {
        return (
            <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100 p-5 gap-3">
                <Label className="fw-semibold text-danger fs-5">This report couldn't load. Please refresh or try again later.</Label>
                <Button
                    size="sm"
                    onClick={() => generateReport()}
                    color="primary"
                    className="btn-label">
                        <i className="ri-refresh-line label-icon align-middle fs-16 me-2"></i>
                        Refresh
                </Button>
            </div>
        )
    }

    if(option) {
        return (
            <ReactEcharts
                style={{ height: "100%", width: "100%" }}
                option={option}
                notMerge={true}
                onEvents={onEvents}
            />
        )
    }
    return null;
};

export default AreaReport;
