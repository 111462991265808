import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label } from 'reactstrap';
import { get, map } from "lodash";

//i18n
import i18n from "../../i18n";
import currency from "../../common/currency";
import { useDispatch } from 'react-redux';
import { currencyGetRequest, currencyUpdateRequest } from 'store/currency/action';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import SimpleBar from 'simplebar-react';
import { isJson, convertCurrency } from 'utils';

interface Country {
    name: string;
    code: string;
    currency: string;
    currency_code: string;
    currency_symbol: string;
    flag: string;
    mobile_code: string;
    language: string;
}

const CurrencyDropdown = () => {
    const dispatch: any = useDispatch();
    const conversionRates = useSelector((state: ApplicationState) => state.currency.conversion);
    const updatedCurrency = useSelector((state: ApplicationState) => state.currency.updatedCurrency);
    const [searchTerm, setSearchTerm] = useState('');
    const [isCurrencyDropdown, setIsCurrencyDropdown] = useState<boolean>(false);
    const [selectedCurrency, setSelectedCurrency] = useState<any>(null);
    const [parsedRates, setParsedRates] = useState<any>(null)
    const [assignRates, setAssignRates] = useState<any>(null)
    const [currencyLocal, setCurrencyLocal] = useState<any>([])
    useEffect(() => {
        localStorage.setItem('selectedCurrency', 'GBP');
        localStorage.setItem('countryCode', "gb");
        localStorage.setItem('selectedCurrencySymbol', '£');
        dispatch(currencyGetRequest())
        dispatch(currencyUpdateRequest())
    }, []);

    useEffect(() => {
        if (conversionRates) {
            const tempArray = [
                localStorage.getItem('selectedCurrency'), // Index 0
                localStorage.getItem('countryCode'),      // Index 1
                localStorage.getItem('selectedCurrencySymbol') // Index 2
            ];
            const specificIndexArray: any = [];
            specificIndexArray.splice(0, 0, ...tempArray);
            setAssignRates(conversionRates);

            // Set currencyLocal directly from specificIndexArray
            setCurrencyLocal(specificIndexArray);
        }
    }, [conversionRates])

    useEffect(() => {
        if (assignRates && assignRates?.length > 0) {
            const targetCurrencyJson = assignRates[0]?.targetCurrencyJson;

            // Check if `targetCurrencyJson` exists and is a valid JSON string
            const convertJson = targetCurrencyJson && isJson(targetCurrencyJson)
                ? JSON.parse(targetCurrencyJson)
                : {};

            // Set the parsed rates only if convertJson is valid
            setParsedRates(convertJson?.conversion_rates);
        }
    }, [conversionRates, selectedCurrency]);

    const changeCurrencyAction = (curr: any) => {
        localStorage.setItem('selectedCurrency', curr.currency_code);
        localStorage.setItem('countryCode', curr.code.toLowerCase());
        localStorage.setItem('selectedCurrencySymbol', curr.currency_symbol);
        const data = {
            selectedCurrency: curr.currency_code,
            selectedCurrencySymbol: curr.currency_symbol,
            countryCode: curr.code.toLowerCase(),
        };
        const handleSuccess = (body: any) => {
        };

        const handleError = (body: any) => {
        };

        // Reset search term
        setSearchTerm('');

        // Dispatch action to update currency in the store
        dispatch(currencyUpdateRequest(data, handleSuccess, handleError));
    };


    const handleSearch = (e: any) => {
        setSearchTerm(e.target.value);
    };

    const filteredCurrencies = currency.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.currency_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.currency_symbol.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const toggleLanguageDropdown = () => {
        setIsCurrencyDropdown(!isCurrencyDropdown);
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isCurrencyDropdown} toggle={toggleLanguageDropdown} className="ms-1 topbar-head-dropdown header-item">
                <DropdownToggle className="text-nowrap btn btn-icon w-md btn-topbar btn-ghost-secondary">
                    {updatedCurrency && updatedCurrency.countryCode ? (
                        <>
                            <img
                                src={updatedCurrency && updatedCurrency.countryCode
                                    ? `https://zilter-assets.s3.eu-west-2.amazonaws.com/1x1/${updatedCurrency.countryCode}.svg`
                                    : ''}
                                // alt="Currency Flag"
                                height="20"
                                className="rounded me-2"
                            />
                            <Label className='mb-0'>
                                {updatedCurrency.selectedCurrency} <span className='ms-2'>{updatedCurrency.selectedCurrencySymbol}</span>
                            </Label>
                        </>
                    ) : (
                        <>
                            <img
                                src={currencyLocal && currencyLocal.length > 0
                                    ? `https://zilter-assets.s3.eu-west-2.amazonaws.com/1x1/${currencyLocal[1]}.svg` // Use the country code from currencyLocal
                                    : ''}
                                height="20"
                                className="rounded me-2"
                            />
                            <Label className='mb-0'>
                                {currencyLocal[0]} <span className='ms-2'>{currencyLocal[2]}</span> {/* Display currency code and symbol */}
                            </Label>
                        </>
                    )

                    }

                </DropdownToggle>
                <DropdownMenu className="notify-item language py-2">
                    <div className="position-relative p-2">
                        <Input
                            type="text"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                        <span className="position-absolute end-0 top-50 translate-middle-y me-3">
                            <i className="ri-search-line"></i>
                        </span>
                    </div>
                    <SimpleBar style={{ maxHeight: "300px", minWidth: "350px" }}>
                        {filteredCurrencies.length > 0 ? (
                            filteredCurrencies.map((item) => (
                                <DropdownItem
                                    key={item.code}
                                    onClick={() => changeCurrencyAction(item)}
                                    className={`notify-item ${selectedCurrency === item.currency_code ? "active" : ""}`}
                                >
                                    <img
                                        src={`https://zilter-assets.s3.eu-west-2.amazonaws.com/1x1/${(item.code).toLowerCase()}.svg`}
                                        // alt={`${item.currency} Flag`}
                                        className="me-2 rounded"
                                        height="18"
                                        width="18"
                                    />
                                    <span className="align-middle">
                                        {item.name} {item.currency_code} ({item.currency_symbol})
                                    </span>
                                </DropdownItem>
                            ))
                        ) : (
                            <div className="p-2 text-center">No results found</div>
                        )}
                    </SimpleBar>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default CurrencyDropdown;