import React, { useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ApplicationState } from 'store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { ToastContainer, toast } from "react-toastify";
import { Action } from 'redux';
import { Button, Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row, Spinner, Table } from "reactstrap";
import { reportFeatureStatusGetRequest, reportActivationRequest, reportsGetRequest, reportDeleteRequest } from "store/report/action";
import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import moment from "moment";
import convertToTitleCase from "helpers/convertToTitleCase";
import { Report } from "store/report/types";
import DeleteModel from "./Common/DeleteModel";
import Paginator from "Components/Common/PaginatorFix";

const Reports: React.FC = (props: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const report_feature_status_loading = useSelector((state: ApplicationState) => state.report.report_feature_status_loading);
    const report_feature_status = useSelector((state: ApplicationState) => state.report.report_feature_status);
    const report_feature_activation_loading = useSelector((state: ApplicationState) => state.report.report_feature_activation_loading);
    const reports_loading = useSelector((state: ApplicationState) => state.report.reports_loading);
    const reports = useSelector((state: ApplicationState) => state.report.reports);
    const reportsPagination = useSelector((state: ApplicationState) => state.report.reports_pagination);
    const navigate = useNavigate();
    const [pagination, setPagination]: any = useState({ page: 1, size: 10 });

    const [search, setSearch] = useState<string>('');
    const [open, setOpen] = useState<string>('');
    const [selectedDelete, setSelectedDelete] = useState<Report | null>(null);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null); 

    useEffect(() => {
        dispatch(reportFeatureStatusGetRequest())
    }, [])

    useEffect(() => {
        if(report_feature_status?.current_version > 0) {
            getReports(pagination?.page, pagination?.size);
        }
    }, [report_feature_status])

    useEffect(() => {
        // Clear any existing timer before setting a new one
        const timer = setTimeout(() => {
            const page = pagination?.page || 1;
            const size = pagination?.size || 10;
            getReports(page, size);
        }, 2000);
    
        // Cleanup function: clear timeout and abort request on unmount or dependencies change
        return () => {
            clearTimeout(timer);
        };
    }, [search]);

    const getReports = (page: number, size: number) => {
        dispatch(reportsGetRequest(search, page, size));
    }

    useEffect(() => {
        if(reportsPagination) {
            setPagination(reportsPagination)
        }
    }, [reportsPagination])

    // Handle search input change
    const onSearch = (value: any) => {
        setPagination({ ...pagination, page: 0 });
        setSearch(value)
    }

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                    getReports(newPageNo, pagination.size);
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                    getReports(newPageNo, pagination.size);
                }
                break;
            case 'first':
                newPageNo = 1;
                setPagination({ ...pagination, page: 1 });
                getReports(newPageNo, pagination.size);
                break;
            case 'last':
                newPageNo = lastPage;
                setPagination({ ...pagination, page: newPageNo });
                getReports(newPageNo, pagination.size);
                break;
            default:
                newPageNo = pageVal;
                setPagination({ ...pagination, page: newPageNo });
                getReports(newPageNo, pagination.size);
                break;
        }
    };

    const onChangePageSize = (event: any) => {
        setPagination({ ...pagination, size: event.target.value })
        getReports(pagination.page, event.target.value);
    }

    const onCreate =  () => {
        navigate('/reports/create');
    }

    const onView =  (id: string) => {
        navigate(`/reports/${id}`);
    }

    const onEdit =  (id: string) => {
        navigate(`/reports/edit/${id}`);
    }


    const onDeleteReport = () => {
        const handleSuccess = () => {
            toast(`Report deleted Successfull.`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            getReports(pagination.page, pagination.size);
            setSelectedDelete(null);
        }
        const handleError = () => {
            toast(`Report deletion error.`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
        }
        dispatch(reportDeleteRequest(selectedDelete?.id, handleSuccess, handleError));
    }

    const onActivate = () => {
        const handleSuccess = () => {
            toast(`Activation Successfull.`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            dispatch(reportFeatureStatusGetRequest());
        }
        const handleError = () => {
            toast(`Activation error.`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
        }
        dispatch(reportActivationRequest(null, handleSuccess, handleError))
    }
    if(report_feature_status?.current_version <= 0) {
        return (
            <React.Fragment>
                <ToastContainer />
                <Card>
                    <CardBody className="d-flex flex-column gap-2 align-items-center p-5">
                        <div className="d-flex flex-column align-items-center justify-content-center h-100 p-5 gap-5">
                            <h1 className="text-primary">Activate Now to Enable Reporting Feature</h1>
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <p>The reporting feature is currently inactive. To access reporting capabilities, please click 'Activate Now.'</p>
                                <p>This will enable customized reports and dashboards tailored to your data.</p>
                                <p>Activation may take a few moments, and once completed, you’ll have full access to all reporting tools.</p>
                            </div>
                            {report_feature_activation_loading ? 
                            <Button color="primary" className="btn-label btn-load" disabled>
                                <i className="ri-tools-fill label-icon align-middle fs-16 me-2"></i>
                                <span className="d-flex align-items-center">
                                    <span className="flex-grow-1 me-2">
                                        Activating...
                                    </span>
                                    <Spinner size="sm" className="flex-shrink-0" role="status"> Activating... </Spinner>
                                </span>
                            </Button> : <Button
                                onClick={() => onActivate()}
                                color="primary"
                                className="btn-label">
                                    <i className="ri-tools-fill label-icon align-middle fs-16 me-2"></i>
                                    Activate Now
                            </Button>}
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <Card>
                <CardBody className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center search-box">
                            <Input
                                type="text"
                                size={14}
                                className="search"
                                placeholder="Search reports..."
                                onChange={(e) => onSearch(e.target.value)}
                                value={search}
                                style={{width: '240px'}}
                            />
                            {search === "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => setSearch("")}></i>}
                        </div>
                        <div>
                            <Button
                                color="primary"
                                className="btn-label"
                                onClick={() => onCreate()}
                            >
                                    <i className="ri-server-line label-icon align-middle fs-16 me-2"></i>
                                    Create Report
                            </Button>
                        </div>
                    </div>
                    { report_feature_status_loading || reports_loading ?
                    <div>
                        <DtsTablePlaceholder
                            columns={[]}
                            tableClass="align-middle table-nowrap"
                            thClass="border-bottom-1 table-soft-primary"
                        />
                    </div>
                    : 
                    <div>
                    {reports?.length ? <div className="d-flex flex-column gap-2">
                        <div className="table-responsive">
                            <Table className="align-middle table-nowrap">
                            <thead className='table-light text-muted position-sticky top-0'>
                                <tr>
                                    <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">Name</th>
                                    <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">Chart</th>
                                    <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">Created at</th>
                                    <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">Updated at</th>
                                    <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reports.map((report: Report, reportIndex: number) => {
                                    return (
                                        <tr key={reportIndex}>
                                            <td className='align-middle fw-semibold'>
                                                <Link to={`/reports/${report.id}`} className="text-primary fw-semibold">{report?.name}</Link>
                                            </td>
                                            <td className='align-middle fw-semibold'>{convertToTitleCase(report?.config?.chart_type)}</td>
                                            <td className='align-middle fw-semibold'>{moment(report?.created_at).format('lll')}</td>
                                            <td className='align-middle fw-semibold'>{moment(report?.updated_at).format('lll')}</td>
                                            <td>
                                                <ul className="list-inline hstack gap-2 mb-0">
                                                    <li className="list-inline-item">
                                                        <Dropdown isOpen={open === report.id} toggle={() => open === report.id ? setOpen("") : setOpen(report.id)}>
                                                            <DropdownToggle
                                                                href="#"
                                                                className="btn btn-soft-primary btn-sm dropdown"
                                                                tag="button"
                                                            >
                                                                <i className="ri-more-fill"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu  container="body" className="dropdown-menu-center">
                                                                <DropdownItem
                                                                    onClick={() => onView(report.id)}
                                                                    className="dropdown-item">
                                                                    <div className="d-flex gap-2 align-items-center">
                                                                        <Button size="sm" color="info" className="btn-icon btn-soft-info">
                                                                            <i className="ri-eye-fill" />
                                                                        </Button>
                                                                        View
                                                                    </div>                                                        
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() => onEdit(report.id)}
                                                                    className="dropdown-item edit-item-btn fs-12">
                                                                    <div className="d-flex gap-2 align-items-center">
                                                                        <Button size="sm" color="primary" className="btn-icon btn-soft-secondary">
                                                                            <i className="ri-pencil-fill" />
                                                                        </Button>
                                                                        Edit
                                                                    </div>
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() => setSelectedDelete(report)}
                                                                    className="dropdown-item remove-item-btn fs-12">
                                                                    <div className="d-flex gap-2 align-items-center">
                                                                        <Button size="sm" color="danger" className="btn-icon btn-soft-danger">
                                                                            <i className="ri-delete-bin-fill" />
                                                                        </Button>
                                                                        Delete
                                                                    </div>
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        </div>
                        <Row className="align-items-center g-3 text-center text-sm-start">
                            <div className="col-sm">
                                <div className="text-muted">
                                    <span className="fw-semibold ms-1">
                                        {pagination ? `${pagination.offset + 1}-${Math.min(pagination.offset + pagination.size, pagination.elements)} of ${pagination.elements} results` : `0-0 of 0 results`}
                                    </span>
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                                    currentPage={pagination?.page}
                                    pageChanged={(e: any) => pageChanged(e)}
                                    pageSize={pagination?.size}
                                    isPageSizeChange={true}
                                    onChangeInSelect={(e: any) => onChangePageSize(e)}
                                    pagination={pagination}
                                />
                            </div>
                        </Row>
                        </div> :
                        <div>
                            <Table className="align-middle table-nowrap">
                                <thead className='table-light text-muted position-sticky top-0'>
                                    <tr>
                                        <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">Name</th>
                                        <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">Created At</th>
                                        <th scope="col" className="border-bottom-1 table-soft-primary fw-bold">Updated At</th>
                                    </tr>
                                </thead>
                            </Table>
                            <div className="d-flex flex-column align-items-center justify-content-center w-100 gap-3" style={{ height: '300px'}}>
                                <Label>There is currently no reports to display. Please ensure reports are added or try refreshing the page.</Label>
                                <div>
                                    <Button
                                        color="primary"
                                        className="btn-label"
                                        onClick={() => onCreate()}
                                    >
                                            <i className="ri-server-line label-icon align-middle fs-16 me-2"></i>
                                            Create Report
                                    </Button>
                                </div>
                            </div>
                        </div>}
                        </div>
                    }
                </CardBody>
            </Card>
            {
                selectedDelete ?
                    <DeleteModel
                        isOpen={!!selectedDelete}
                        toggle={() => setSelectedDelete(null)}
                        report={selectedDelete}
                        onDelete={() => onDeleteReport()}
                    /> : null
            }
        </React.Fragment>
    );
};
export default withTranslation()(Reports);
