import { useEffect, useState } from "react";
import {
    Label,
    Input,
    FormFeedback,
    Button,
    ButtonGroup,
} from "reactstrap";
import Select from "react-select";
import { components } from "react-select";
import { createSelector } from 'reselect';
import { useSelector } from "react-redux";

interface Option {
    label: string;
    value: string;
}
interface DtsSelectInputProps {
    label?: string;
    type?: string;
    value: string;
    placeholder?: string;
    onChange: (value: string) => void;
    isEditable?: boolean;
    name?: string;
    errorText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    actionButtons?: boolean;
    defaultValue?: any;
    rest?: any;
    invalid?: any;
    formValues?: any;
    dataFields?: any;
    options?: any;
    onSaveClick?: any;
    setdisabled?: any;
    setError?: any;
    formerror?: any;
    classes?: any;
    resetvalue?: any;
    isTarget?: any;
}

const DtsSelectInput: React.FC<DtsSelectInputProps> = ({isTarget, resetvalue,classes, setdisabled, onSaveClick, options, formValues, dataFields, invalid, rest, defaultValue, label, type, value, placeholder, onChange, isEditable, name ,icon, iconPosition, errorText, actionButtons, readOnly, disabled}: any) => {
    const [hover, setHover] = useState<any>(false)
    const [valid, setvalid] = useState<any>(false)
    const [formoptions, setOptions] = useState<any>(false)
    const [defaultoption, setDefaultOption] = useState<any>(null)
    const [resetValue, setResetValue] = useState<any>()
    const [errormsg, setErrormsg] = useState();
    const selectDashboardData = createSelector(
        (state: any) => state.Layout,
        (state) => ({
            layoutModeType: state.layoutModeType
        })
    );
    // Inside your component
    const { layoutModeType } = useSelector(selectDashboardData);
    const onhandleChange = (value: any) => {
        if(value == null){
            onChange("");
            setDefaultOption(value)
        }      
        else {
            onChange(value)
            const isValidInput = validateInput(value);
            setvalid(isValidInput);
        }
    }

    useEffect(() => {
        onChange && onChange(resetvalue)
        setResetValue(resetvalue)
    }, [resetvalue])

    useEffect(() => {
        if(rest && rest.validate && rest.validate.required) {           
            if(value === '')
            { 
                const inputdiv: any = document.getElementById(`${name}input`);
                if(inputdiv) inputdiv.classList.add('d-block');
            } 
            else {
                const inputdiv: any = document.getElementById(`${name}input`);
                if(inputdiv) inputdiv.classList.remove('d-block');
            }            
        }
    },[value])

    const validateInput = (value: any) => { 
        if(rest && rest.validate && rest.validate.required) {
            if(value === "") {
               let error: any =  `Please enter ${label}`;
                setErrormsg(error)
                return false
            }
        }
        setErrormsg(undefined)
        return true
    }
    useEffect(() => {
        if(rest && rest.validate) {           
            if(errormsg)
            { 
                if (typeof setdisabled === 'function') {
                    setdisabled(true)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.add('d-block');
            } 
            else {
                if (typeof setdisabled === 'function') {
                    setdisabled(false)
                }
                const inputdiv: any = document.getElementById(`${name}feedback`);
                if(inputdiv) inputdiv.classList.remove('d-block');
            }            
        }
    },[value])

    useEffect(() => {
        if(rest && rest.validate && rest.validate.required) { 
            const feedback: any = document.getElementById(`starred${name}`);
            if(feedback) feedback.classList.remove('d-none');
        }
        if(rest && rest.label) {
            if(rest.type ==='checkbox') {
                options && options.map((item: any) =>{
                    if(item.value === defaultValue) {
                        let defaultoption = item;
                        setDefaultOption(defaultoption);
                    }
                })
            }
            else if(defaultValue) {
                rest.values && rest.values.map((item: any) =>{
                    if(item.value === defaultValue) {
                        let defaultoption = item;
                        setDefaultOption(defaultoption);
                    }
                })
                
            }
            else {       
                setDefaultOption(null);
            }
            
    }
    else {
        setDefaultOption(undefined);
    }
    },[rest])

    const onCheckClick = (e: any) => {
        e.preventDefault();
        onSaveClick();
        setHover(false)      
    }
    const CustomOption = (props: any) => {
        return (
          <components.Option {...props}>
            <div className='hstack justify-content-between'>
                {props.children}
                {props.isSelected && <i className="ri-check-line fs-20" />}
            </div>
          </components.Option>
        );
      };


      const customStyles = {
        // Customize the style of the dropdown menu
        control: (provided: any, state: any) => ({
                    ...provided,
                    // color: '#495057',
                    fontWeight: 900,
                    width: '100%',
                    // border: state.isFocused ? '0.5px solid lightgrey' : '0.5px solid lightgrey',
                    borderRadius: 3,
                    boxShadow: 'none',
                    '&:hover': {
                    // border: '0.5px solid lightgrey',
                    },
                    backgroundColor: 'transparent'
        }),
        menu: (provided: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
          borderRadius: 4
        }),
        menuPortal: (base: any) => {           
            const { zIndex, ...rest } = base;
            return { ...rest, zIndex: 9999 };
        },
        // Customize the style of the option in the dropdown menu
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
          '&:hover': {
            backgroundColor: layoutModeType === 'light' ? 'white' : '#495057',
          },
        }),
    }


    
    useEffect(() => {
        if(rest && rest.type==="checkbox") {
            setOptions(options);
        }
        else {
            let newoptions = options && options.length && options.sort((a: any, b: any) => a.id - b.id);
            let tempOptions = newoptions && newoptions.length && newoptions.filter((a: any, b: any) => a.in_forms == true);
            setOptions(tempOptions);
        } 
    },[options])

    useEffect(() => {
        if(rest?.isMulti){
            if(rest.type === "select")   setDefaultOption(resetvalue)
            else {
                let newoption: any = []
                resetvalue && resetvalue.length && resetvalue.map((item: any) => {
                    newoption.push({label: item, value: item, in_forms:true}) 
                })
                setDefaultOption(newoption)
            }
        }
        else {
            if(resetvalue) {
                options && options.length &&  options.map((item: any) =>{
                    if(item.value === resetvalue || item.label === resetvalue) {
                        setResetValue(resetvalue)
                        setDefaultOption(item)
                    }
                })
            }
            else {
                setDefaultOption(null)
            }                    
        }
    }, [resetvalue, options])
    

    useEffect(() => {
        if(rest?.isMulti) setDefaultOption(defaultValue)
        else {
            options && options.length &&  options.map((item: any) =>{
                if(item.value === defaultValue || item.label === defaultValue) {
                    let defaultoption = item;
                    setDefaultOption(defaultoption);
                }
            })
        }
    }, [defaultValue])
    return (   
            <div className={classes ? classes : 'pb-3'}> 
            {
                actionButtons ?
                <div>
                    { hover ?
                    <div>
                        {label ? <Label htmlFor={name} className="form-label fw-bold fs-14 mb-2">{label}<span id={`starred${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label>: null}
                        <div className="d-flex border rounded-3 align-items-center">
                            <Select {...rest} 
                                menuPortalTarget= {document.getElementById('root')}
                                styles={customStyles}
                                components={{ Option: CustomOption }}
                                className='form-control form-select-sm p-0 border-0 d-flex align-items-center'   
                                defaultValue={defaultoption}
                                type={type}
                                isMulti={rest?.isMulti}
                                id={`id${name}`}
                                placeholder={placeholder ? placeholder : `Select ${rest.label}`}
                                onChange={(selectedOption: any) => {onChange(selectedOption); setDefaultOption(selectedOption);}}
                                options={formoptions}
                                readOnly={readOnly}
                                disabled={disabled}
                                value={defaultoption}
                                onKeyDown={(e: any) => {
                                    if (e.key === 'Enter') {
                                      e.preventDefault();
                                    }
                                  }}   
                                menuPosition="fixed"
                                ></Select>
                                <ButtonGroup>
                                    <Button onClick={(e) => { e.preventDefault();  setHover(false); onhandleChange(resetValue) }} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-close-fill" /> </Button>
                                    <Button onClick={(e) => {onCheckClick(e)}} color="primary" className="btn-icon"> <i className="ri-check-fill" /> </Button>
                                </ButtonGroup>
                        </div>   
                        {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}                     
                    </div>
                    : <div>
                        {label ? <Label htmlFor={name} className="form-label fw-bold fs-14 mb-0">{label}<span id={`starred${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label>: null}              
                        <div className="hstack justify-content-between topbar-user">
                            {rest && rest?.isMulti ?                             
                                <div className="fs-14 fw-medium form-control border-0 text-nowrap text-truncate text-nowrap-ellipsis topbar-user">
                                    {defaultoption && defaultoption.length ? 
                                        defaultoption.map((item: any, index: any) => {
                                            if(item.label){
                                                return <span>{item.label}{index+1 !== defaultoption.length ? ", " : ''}</span>
                                            }
                                            else return <span>{item}{index+1 !== defaultoption.length ? ", " : ''}</span>
                                        })
                                    : null}
                                </div>
                                :
                                <div className="fs-14 fw-medium form-control border-0 text-nowrap text-truncate text-nowrap-ellipsis topbar-user">
                                    {defaultoption && defaultoption.label ? defaultoption.label : value}
                                </div>
                            }
                            {
                                readOnly ? null
                                : <Button onClick={() => setHover(true)} color="primary" className="btn-icon btn-soft-primary"> <i className="ri-edit-2-fill" /> </Button>
                            }
                            
                        </div>
                    </div>
                    }
                </div>
            :            
                <div>
                    {label ? <Label htmlFor={name} className="form-label fw-bold fs-14 mb-2">{label}<span id={`starred${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label>: null}
                    <Select {...rest} 
                    styles={customStyles}
                    menuPortalTarget= {document.getElementById('root')}
                    menuPosition="fixed"
                    components={{ Option: CustomOption }}
                    className='form-select-sm p-0'                    
                    defaultValue={defaultoption} 
                    type={type} 
                    isMulti={rest?.isMulti}
                    isDisabled={readOnly}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                        }
                      }}   
                    id={`id${name}`} 
                    placeholder={placeholder ? placeholder : rest && rest.label ? `Select ${rest.label}`: ''}  onChange={(selectedOption: any) => {onChange(selectedOption); setDefaultOption(selectedOption);}}  options={formoptions} readOnly={readOnly} disabled={disabled} 
                    value={defaultoption}
                    ></Select>
                    {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}
                </div>
            }
            </div>
    );
};

export default DtsSelectInput;
