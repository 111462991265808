import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Select from 'react-select'
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { toast } from "react-toastify";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import FollowUpTask from "./FollowUpTask";
import Comments from "./Comments";
import SimpleBar from "simplebar-react";

interface FProps {
    props: any;
    notesModals: boolean;
    setIsNotesOpen: (event: boolean) => void;
    record: any,
    model: string,
    noteAddRequest: any,
    notesListGetRequest: any,
}

const noteType = [
    {
        options: [
            { label: "Counselling Session Summary", value: "COUNSELLING SESSION SUMMARY" },
            { label: "Follow-up call", value: "FOLLOW-UP CALL" },
            { label: "Incoming Call", value: "INCOMING CALL" },
            { label: "Attempt to book", value: "ATTEMPT TO BOOK" },
            { label: "Proof of Funds Details", value: "PROOF OF FUNDS DETAILS" },
            { label: "Course Notes", value: "COURSE NOTES" },
            { label: "Finance Notes", value: "FINANCE NOTES" },
            { label: "Others", value: "OTHERS" }
        ],
    },
];

const NotesModal: React.FC<FProps> = ({
    props,
    notesModals,
    setIsNotesOpen,
    record,
    model,
    noteAddRequest,
    notesListGetRequest
}) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [newNote, setNewNote] = useState("");
    const [selectedAddNoteType, setSelectedAddNoteType] = useState(null);
    const [addShortNoteValue, setAddShortNoteValue] = useState("");
    const [studentId, setStudentId] = useState('')
    const [tasksModals, setTasksModals] = useState<boolean>(false);
    const [followupRequired, setFollowupRequired] = useState(false)
    const [transcribing, setTranscribing] = useState("modalparent")
    const [isAddNoteButtonDisabled, setIsAddNoteButtonDisabled] = useState<boolean>(false);
    const [applicationId, setApplicationId] = useState('')

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition({ transcribing: transcribing == "modalparent" }); 

    const handleGetList = () => {
        if(model == "students") {
            if (record && record.id) {
                setStudentId(record.id)
                dispatch(notesListGetRequest(record.id));
            }
        }
        else {
            if (record && record.id) {
                setApplicationId(record.id)
                let studentsId = record && record?.valuesJson.student && record?.valuesJson.student.id ? record?.valuesJson.student.id : "";
                setStudentId(studentsId)
                dispatch(notesListGetRequest(studentsId, {applicationId: record.id}))
            }
        }     
    }

    useEffect(() => {
        handleGetList()   
    }, [record])


    useEffect(() => {
        setNewNote(transcript);
    }, [transcript])

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    const startListening = () => {
        SpeechRecognition.startListening({ continuous: true });
    };

    const stopListening = () => {
        SpeechRecognition.stopListening();
    };

    const handleNewNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewNote(event.target.value);
    };

    const handleAddNoteTypeChange = (selectedOption: any) => {
        setSelectedAddNoteType(selectedOption);
        setAddShortNoteValue(selectedOption.label)
    };


    const handleAddNote = () => {
        stopListening();
        setIsAddNoteButtonDisabled(true);
        const data =
        {
            studentId: studentId,
            applicationId: applicationId,
            shortNote: addShortNoteValue,
            parentId: "",
            note: newNote || transcript
        }
        const handleSuccess = (body: any): void => {
            createFollowUp()
            handleReset();
            handleGetList()
        };

        const handleError = (body: any): void => {
        };

        dispatch(noteAddRequest(data, handleSuccess, handleError));
    };

    const handleReset = () => {
        setNewNote("");
        setSelectedAddNoteType(null)
        // setIsNotesOpen(false);
        setIsAddNoteButtonDisabled(false);
        resetTranscript();
        setAddShortNoteValue('')
    };

    const handleFollowupCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFollowupRequired(event.target.checked);
    };

    const createFollowUp = () => {
        setTasksModals(followupRequired)
    }
    return (
        <>
            <FollowUpTask
                followupRequired={followupRequired}
                setFollowupRequired={setFollowupRequired}
                record={record}
                props={props}
                tasksModals={tasksModals}
                setTasksModals={setTasksModals}
                model={model}
            />
            {/* <Col lg={9}> */}
                <Modal className="w-75" id="modalForm" autoFocus={true} isOpen={notesModals} toggle={() => {
                    setIsNotesOpen(false);
                }} centered={true} size="lg">
                    <ModalHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={() => {
                        setIsNotesOpen(false);
                    }}>
                        <span className="text-light">{"Add New Note"}</span>                        
                    </ModalHeader>
                    <ModalBody className="my-2 p-1">
                        <Row className="g-3">
                            <Col xs={12} >
                                <div className="mx-3">
                                    <textarea className="form-control bg-light border-light" id="exampleFormControlTextarea1" value={newNote}
                                        onChange={handleNewNoteChange} rows={3} placeholder={props.t("applications.add_note")}></textarea>
                                </div>
                            </Col>
                            <Col xs={12} className="text-start">
                                {/* <Dictaphone /> */}
                                <div className='mx-3 d-flex justify-content-end gap-2'>
                                    <div className="form-check mb-0 align-self-center">
                                        <Input className="form-check-input" type="checkbox" onChange={handleFollowupCheck} id="formCheck6" />
                                        <Label className="form-check-label" for="formCheck6">
                                            Follow-up required ?
                                        </Label>
                                    </div>
                                    <div style={{ minWidth: '250px' }}>
                                        <Select
                                            placeholder="Select Note Type"
                                            classNamePrefix="js-example-data-array"
                                            isLoading={false}
                                            options={noteType}
                                            value={selectedAddNoteType}
                                            onChange={handleAddNoteTypeChange}
                                        />
                                    </div>
                                    {listening ? (
                                        <>
                                            <Button color="danger" className="ms-2 btn-icon" outline type="button" onClick={stopListening}> <i className="ri-mic-off-line"></i> </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Button color="primary" className="ms-2 btn-icon" outline type="button" onClick={startListening}> <i className="ri-mic-line"></i> </Button>
                                        </>
                                    )}

                                    <Button color="primary" className="ms-2 btn btn-primary"
                                        disabled={isAddNoteButtonDisabled || (!newNote || !selectedAddNoteType) && (!transcript || !selectedAddNoteType)}
                                        onClick={handleAddNote}>{props.t("applications.add_note")}</Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SimpleBar style={{maxHeight:300}}>
                                    <Comments noteAddRequest={noteAddRequest} notesListGetRequest={notesListGetRequest} record={record} noteType={noteType} transcribing={transcribing} setTranscribing={setTranscribing} model={model} />
                                </SimpleBar>
                            </Col>
                          </Row>
                    </ModalBody>
                </Modal>
            {/* </Col> */}
        </>
    );
};

export default withTranslation()(NotesModal);
