import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Label, Table, Input, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import axios from 'axios'
import { useDispatch, useSelector } from "react-redux";
import { confirmRecommendations, getAuthorizationFormDetails } from "store/recommendation/action";
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import RecommendSuccess from '../../AuthorizationLetters/StudentForm';
import html2pdf from 'html2pdf.js';
import { ApplicationState } from 'store';
import moment from 'moment';
import InPersonVerify from '../../AuthorizationLetters/InPersonVerify';
import config from "../../../config";
interface Inputs {
    password: string;
    confirm_password: string;
}

interface ResetPasswordProps {
    t: (key: string) => string;
}

const { DTS_API_STUDENT, AWS_API } = config.api;

const AgentChangeForm: React.FC<ResetPasswordProps> = ({ t }) => {
    const { accessToken, applicationId } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const elementRef = useRef(null);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const recommendDetails = useSelector((state: ApplicationState) => state.recommend.formDetails);
    const [name, setName] = useState<any>(null)
    const [companyName, setCompanyName] = useState<any>(null)
    const [confirmedList, setConfirmed] = useState<any>([]);
    const [selectedList, setSelectedList] = useState<any>([]);
    const [linktrue, setLinktrue] = useState<boolean>(false);
    const [isConfirm, setIsConfirm] = useState<boolean>(false);
    const [dataReceived, setDataReceived] = useState<any>()
    const [modalOpen, setModalOpen] = useState(false);
    const [selfieData, setSelfieData] = useState<string | null>(null);
    const [verificationCode, setVerificationCode] = useState<string | null>(null);
    const [showButtons, setShowButtons] = useState(true);

    const generatePDF = () => {
        const element = document.getElementById("form-content");
        if (element) {
            setShowButtons(false);
            const opt = {
                margin: [10, 0, 10, 0], // Top, Left, Bottom, Right margins in mm
                filename: 'authorization_form.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };
            html2pdf()
                .from(element)
                .set(opt)
                .toPdf()
                .get('pdf')
                .then((pdf: any) => {
                    const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
                    const selfieSection = document.getElementById("selfie-section");
                    const selfieHeight = selfieSection?.clientHeight || 0;
                    if (selfieHeight >= (pageHeight - 20)) {
                        pdf.addPage(); // Add a new page if the selfie is too large
                    }
                    if (selfieSection) {
                        pdf.html(selfieSection, {
                            x: 20,
                            y: pageHeight + 300, // Place it on the next page
                            callback: function (doc: any) {
                                doc.save('authorization_form.pdf');
                            }
                        });
                    } else {
                        pdf.save('authorization_form.pdf');
                        setShowButtons(true);

                    }
                });
        }
    };

    const handleSelfieCapture = (selfie: string, code: string) => {
        setSelfieData(selfie);
        setVerificationCode(code);
        toast.success("Selfie captured successfully!");
        toggleModal(); // Close the modal after capturing the selfie
    };

    useEffect(() => {
        const handleSuccess = () => { setDataReceived(recommendDetails) }
        const handlefailure = () => { }
        dispatch(getAuthorizationFormDetails(accessToken, "applications",applicationId, handleSuccess, handlefailure))
    }, [dispatch])


    useEffect(() => {
        const parsedData: any = recommendDetails && recommendDetails.length ? recommendDetails.map((item: any) => {
            const valuesJson = item.valuesJson ? item.valuesJson : {};
            const student = valuesJson?.student
            const course = valuesJson?.courses;
            const university = valuesJson?.institutes;
            let values = { id: item.id, createdAt: item.createdAt, company_name: item.companyName, course: course, university: university }
            setName(student?.name)
            setCompanyName(item?.companyName)
            return values
        }) : []
        let courses = parsedData && parsedData.length && parsedData
        setSelectedList(courses)
    }, [recommendDetails])

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleRetakeSelfie = () => {
        setSelfieData(null); // Reset the selfie data to allow for retaking the selfie
        toggleModal(); // Open the modal to retake the selfie
    };

    return (
        <>
            <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                <ModalHeader toggle={toggleModal}>Capture Selfie</ModalHeader>
                <ModalBody>
                    <InPersonVerify onSelfieCapture={handleSelfieCapture} />
                </ModalBody>
            </Modal>
            <ToastContainer />
            {
                isConfirm ?
                    <RecommendSuccess />
                    :
                    <Row id="form-content" className="justify-content-center">
                        <Col xs={7} sm={7} lg={7} md={7} xl={7} className="justify-content-center">
                            <Row className="justify-content-center">
                                <Col >
                                    <Card>
                                        <div className="bg-info-subtle position-relative">
                                            <CardBody className="card-body p-5">
                                                <div className="text-center">
                                                    <h3>Authorization Form</h3>
                                                </div>
                                            </CardBody>
                                            <div className="shape">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    version="1.1"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    xmlns-svgjs="http://svgjs.com/svgjs"
                                                    width="1440"
                                                    height="60"
                                                    preserveAspectRatio="none"
                                                    viewBox="0 0 1440 60"
                                                >
                                                    <g mask='url("#SvgjsMask1001")' fill="none">
                                                        <path
                                                            d="M 0,4 C 144,13 432,48 720,49 C 1008,50 1296,17 1440,9L1440 60L0 60z"
                                                            style={{ fill: "var(--vz-secondary-bg)" }}
                                                        ></path>
                                                    </g>
                                                    <defs>
                                                        <mask id="SvgjsMask1001">
                                                            <rect width="1440" height="60" fill="#ffffff"></rect>
                                                        </mask>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                        <CardBody className="card-body p-2">
                                            <div className="d-flex justify-content-end">
                                                <div>
                                                    <Label>Date: {moment().format("MM/DD/YYYY")}</Label>
                                                </div>
                                            </div>
                                            <Table className="table-bordered align-middle mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: "40%", whiteSpace: "nowrap" }}>First Name</td>
                                                        <td style={{ width: "60%" }}>{recommendDetails?.valuesJson?.student?.first_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "40%", whiteSpace: "nowrap" }}>Last Name</td>
                                                        <td style={{ width: "60%" }}>{recommendDetails?.valuesJson?.student?.last_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "40%", whiteSpace: "nowrap" }}>Date of Birth:</td>
                                                        <td style={{ width: "60%" }}>{recommendDetails?.valuesJson?.student?.date_of_birth ? moment(recommendDetails?.valuesJson?.student?.date_of_birth).format("DD/MMM/YYYY") : ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "40%", whiteSpace: "nowrap" }}>Country of Nationality:</td>
                                                        <td style={{ width: "60%" }}>{recommendDetails?.valuesJson?.student?.nationality}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "40%", whiteSpace: "nowrap" }}>University application reference Number/UCAS ID</td>
                                                        <td style={{ width: "60%" }}>UCAS564567</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "40%", whiteSpace: "nowrap" }}>Course Applied</td>
                                                        <td style={{ width: "60%" }}>{recommendDetails?.valuesJson?.courses?.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "40%", whiteSpace: "nowrap" }}>Date Application Submitted</td>
                                                        <td style={{ width: "60%" }}>
                                                            {recommendDetails?.createdAt ? moment(recommendDetails?.createdAt).format("DD/MMM/YYYY") : ""}
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "40%", whiteSpace: "nowrap" }}>Your Agency</td>
                                                        <td style={{ width: "60%" }}>{
                                                            recommendDetails?.valuesJson?.courses?.agency ? recommendDetails?.valuesJson?.courses?.agency : ""
                                                        }</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ width: "40%", whiteSpace: "nowrap" }}>Reason(s) for taking assistance:</td>
                                                        <td style={{ width: "60%" }}>
                                                            {recommendDetails?.valuesJson?.reason_for_taking_assistance ? recommendDetails?.valuesJson?.reason_for_taking_assistance
                                                                : <Input />}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                            <div className='d-flex justify-content-start'>
                            <div className="">
                                <h5>Authorization Instructions</h5>
                                <p>To complete your verification process, we just need you to confirm your identity with a quick selfie.</p>

                                <h5>How to proceed:</h5>
                                <ol>
                                    <li>Click on the <strong>"Capture Selfie"</strong> button below.</li>
                                    <li>Ensure you are in a well-lit environment for clear visibility.</li>
                                    <li>Write the <strong>verification code</strong> provided to you on a piece of paper.</li>
                                    <li>Hold the paper with the verification code <strong>clearly visible below your face</strong> while taking the selfie.</li>
                                    <li>Align your face within the frame and click the button to capture your selfie.</li>
                                    <li>Once the selfie is taken, it will be automatically uploaded for verification.</li>
                                </ol>
<br></br>
                                <p>Your privacy and security are important to us. The selfie and verification code will only be used for verification purposes.</p>

                                <p>Thank you for your cooperation!</p>
                            </div>

                        </div>
                            </Row>
                        </Col>
                        {/* <div style={{ height: "300px"}} className='display-none'>&nbsp;</div> */}
                       


                        {selfieData && (
                            <div id="selfie-section" className="mt-4 text-center">
                                <div>
                                    <Label>Verification Code: {verificationCode}</Label>
                                </div>
                                <img
                                    src={selfieData}
                                    alt="Captured Selfie"
                                    style={{ maxWidth: "100%", maxHeight: "300px", borderRadius: "10px" }}
                                />
                            </div>
                        )}
                        <div className="text-center">
                            {showButtons ? (
                                <>
                                    {selfieData ? (
                                        <>
                                            <Button onClick={handleRetakeSelfie}>Retake Selfie</Button>
                                            <Button color="primary" onClick={generatePDF}>
                                                Generate PDF
                                            </Button>
                                        </>
                                    ) : (
                                        <Button onClick={toggleModal}>Capture Selfie</Button>
                                    )}
                                </>
                            ) : null}
                        </div>
                    </Row>
            }

        </>
    );
};

export default withTranslation()(AgentChangeForm);