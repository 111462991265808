import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import { Button, Label } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Report from "./Report";
import ControlPanel from "./MainPanel/ControlPanel/ControlPanel";
import DataPanel from "./MainPanel/DataPanel/DataPanel";
import convertToTitleCase from "helpers/convertToTitleCase";
import { periods } from "common/data/period";
import { frequencies } from "common/data/frequencies";
import SaveModel from "../SaveModel";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { reportClearRequest } from "store/report/action";
import EditModel from "../EditModel";

interface ConfigProps {
    reportConfig: any;
    t: (key: string) => string;
}
const Config: React.FC<ConfigProps> = ({reportConfig, t}) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const navigate = useNavigate();

    const [reportName, setReportName] = useState('')
    const [columns, setColumns]: any = useState([])
    const [filters, setFilters]: any = useState([])
    const [filterCondition, setFilterCondition]: any = useState(null)
    const [dataSources, setDataSources] = useState([])
    const [relationships, setRelationships]: any = useState(null)
    const [config, setConfig]: any = useState(null)
    const [chart, setChart]: any = useState('table')
    const [groupBy, setGroupBy]: any = useState(null)
    const [value, setValue]: any = useState(null)
    const [compareBy, setCompareBy]: any = useState(null)
    const [xAxis, setXAxis]: any = useState(null)
    const [yAxis, setYAxis]: any = useState([])
    const [fields, setFields]: any = useState([])
    const [saveModel, setSaveModel] = useState(false)
    const [showResult, setShowResult] = useState(false)
    const [edit, setEdit] = useState(false)

    useEffect(() => {
        if(reportConfig) {
            setReportName(reportConfig?.name)
            setConfig(reportConfig?.config);
            setDataSources(reportConfig?.config?.data_sources);
            setColumns(reportConfig?.config?.columns)
            setFilters(reportConfig?.config?.filters)
            setFilterCondition(reportConfig?.config?.filter_condition)
            setChart(reportConfig?.config?.chart_type)
            setGroupBy(reportConfig?.config?.group_by)
            setValue(reportConfig?.config?.value)
            setCompareBy(reportConfig?.config?.compare_by)
            setXAxis(reportConfig?.config?.x_axis)
            setYAxis(reportConfig?.config?.y_axis)
            setFields(reportConfig?.config?.fields)
            setEdit(true);
        }
    }, [reportConfig])

    useEffect(() => {
        dispatch(reportClearRequest())
        setShowResult(false)
        const config = {
            data_sources: dataSources,
            relationships: relationships,
            columns: columns,
            filters: filters,
            filter_condition: filterCondition,
            limit: 1000,
            page: 1,
            include_pagination: true,
            chart_type: chart,
            group_by: groupBy,
            value,
            compare_by: compareBy,
            x_axis: xAxis,
            y_axis: yAxis,
            fields: fields
        }
        setConfig(config);
    }, [filters, filterCondition, columns, dataSources, relationships, chart, groupBy, value, compareBy, xAxis, yAxis, fields]);

    const onBack =  () => {
        navigate('/reports');
    }

    const onRun = () => {
        dispatch(reportClearRequest());
        setShowResult(true)
    }

    const onAddColumn = (property: any, dataSource: any) => {
        setColumns((preColumns: any) => [...preColumns,
            {
                key: property.column_name,
                label: property.label ? property.label : convertToTitleCase(property.column_name),
                type: property.column_type,
                optionLabel: property.optionLabel,
                options: property.values,
                model: property.model,
                json_field: property.is_json_field,
                data_source: dataSource
            }])
    }

    // Function to handle moving statuses in the list
    const moveColumns = useCallback((dragIndex: number, hoverIndex: number) => {
        setColumns((prevColumns: any) => {
            const updatedColumns = [...prevColumns];
            const [draggedStatus] = updatedColumns.splice(dragIndex, 1);
            updatedColumns.splice(hoverIndex, 0, draggedStatus);
            return updatedColumns;
        });
    }, []);

    // Update or add column
    const onColumnUpdated = (newColumn: any) => {
        const updatedColumns = columns.map((column: any) => {
            if (newColumn.key === column.key && newColumn.data_source.value === column.data_source.value) {
                return {
                    ...column,
                    ...newColumn
                };
            }
            return column;
        });
        setColumns(updatedColumns)
    }

    // Delete column
    const onColumnDelete = (deleteColumn: any) => {
        const filteredColumns = columns.filter(
            (column: any) => column.key !== deleteColumn.key || column.data_source.value !== deleteColumn.data_source.value
        );
        setColumns(filteredColumns)
    }

    
    const onAddFilters = (property: any, dataSource: any) => {
        setFilters((preFilters: any) => [...preFilters, {
            key: property.column_name,
            label: property.label ? property.label : convertToTitleCase(property.column_name),
            type: property.column_type,
            optionLabel: property.optionLabel,
            options: property.values,
            model: property.model,
            json_field: property.is_json_field,
            data_source: dataSource
        }])
    }

    // Update or add filter
    const onFilterUpdated = (newFilter: any, condition: any) => {
        const updatedFilters = filters.map((filter: any) => {
            if (newFilter.key === filter.key) {
                return {
                    ...filter,
                    ...newFilter
                };
            }
            return filter;
        });
        setFilters(updatedFilters);
        const activeFilters = updatedFilters.filter(
            (filter: any) => filter?.values?.length || filter?.value
        );
        const result = activeFilters.map((item: any) => item.id).join(` ${condition.value} `);
        setFilterCondition(result)
    }

    // Delete column
    const onFilterDelete = (deleteFilter: any, condition: any) => {
        const updatedFilters = filters.filter(
            (filter: any) => filter.key !== deleteFilter.key || filter.data_source.value !== deleteFilter.data_source.value
        );
        setFilters(updatedFilters)
        const activeFilters = updatedFilters.filter(
            (filter: any) => filter?.values?.length || filter?.value
        );
        const result = activeFilters.map((item: any) => item.id).join(` ${condition.value} `);
        setFilterCondition(result)
    }

    const onModifyGroupBy = (property: any, dataSource: any) => {
        if(property && dataSource) {
            setGroupBy({
                key: property.column_name,
                label: property.label ? property.label : convertToTitleCase(property.column_name),
                type: property.column_type,
                optionLabel: property.optionLabel,
                options: property.values,
                model: property.model,
                json_field: property.is_json_field,
                data_source: dataSource
            })
        } else {
            setGroupBy(null)
        }
    }

    const onModifyValue = (property: any, dataSource: any) => {
        if(property && dataSource) {
            const label = property?.measure ? property.label : property.label ? `Count of ${property.label}` : `Count of ${convertToTitleCase(property.column_name)}`;
            setValue({
                key: property.column_name,
                label: label,
                type: property.column_type,
                optionLabel: property.optionLabel,
                options: property.values,
                model: property.model,
                json_field: property.is_json_field,
                data_source: dataSource,
                aggregation: {
                    label: "Count",
                    value: "COUNT"
                },
            })
        } else {
            setValue(null)
        }
    }

    const onModifyCompareBy = (property: any, dataSource: any) => {
        if(property && dataSource) {
            setCompareBy({
                key: property.column_name,
                label: property.label ? property.label : convertToTitleCase(property.column_name),
                type: property.column_type,
                optionLabel: property.optionLabel,
                options: property.values,
                model: property.model,
                json_field: property.is_json_field,
                data_source: dataSource,
                period: periods[0]
            })
        } else {
            setCompareBy(null)
        }
    }

    const onModifyXAxis = (property: any, dataSource: any) => {
        if(property && dataSource) {
            let xAxis: any = {
                key: property.column_name,
                label: property.label ? property.label : convertToTitleCase(property.column_name),
                type: property.column_type,
                optionLabel: property.optionLabel,
                options: property.values,
                model: property.model,
                json_field: property.is_json_field,
                data_source: dataSource
            }
            if(property.column_type === 'datetime') {
                xAxis = {...xAxis, frequency: frequencies[0]}
            }
            setXAxis(xAxis)
        } else {
            setXAxis(null)
        }
    }

    const onModifyYAxis = (property: any, dataSource: any) => {
        if(property && dataSource) {
            const label = property?.measure ? property.label : (property.label ? `Count of ${property.label}` : `Count of ${convertToTitleCase(property.column_name)}`);
            setYAxis([{
                key: property.column_name,
                label: label,
                type: property.column_type,
                optionLabel: property.optionLabel,
                options: property.values,
                model: property.model,
                json_field: property.is_json_field,
                data_source: dataSource,
                aggregation: {
                    label: "Count",
                    value: "COUNT"
                },
            }])
        } else {
            setYAxis([])
        }
    }

    const onAddField = (property: any, dataSource: any) => {
        setFields((preFields: any) => [...preFields,
            {
                key: property.column_name,
                label: property.label ? property.label : convertToTitleCase(property.column_name),
                type: property.column_type,
                optionLabel: property.optionLabel,
                options: property.values,
                model: property.model,
                json_field: property.is_json_field,
                data_source: dataSource
            }])
    }

    // Function to handle moving statuses in the list
    const moveFields = useCallback((dragIndex: number, hoverIndex: number) => {
        setFields((prevFields: any) => {
            const updatedFields = [...prevFields];
            const [draggedStatus] = updatedFields.splice(dragIndex, 1);
            updatedFields.splice(hoverIndex, 0, draggedStatus);
            return updatedFields;
        });
    }, []);

    // Update or add field
    const onFieldUpdated = (newField: any) => {
        const updatedFields = fields.map((field: any) => {
            if (newField.key === field.key && newField.data_source.value === field.data_source.value) {
                return {
                    ...field,
                    ...newField
                };
            }
            return field;
        });
        setFields(updatedFields)
    }

    // Delete field
    const onFieldDelete = (deleteField: any) => {
        const filteredFields = fields.filter(
            (field: any) => field.key !== deleteField.key || field.data_source.value !== deleteField.data_source.value
        );
        setFields(filteredFields)
    }

    let showValidation = false;
    let validationMessage = '';
    switch(chart) {
        case 'count':
        case 'pie':
        case 'donut':
            if(value === null) {
                showValidation = true;
                validationMessage = 'Add value to display the report.'
            }
            break;
        case 'bar':
        case 'line':
        case 'area':
            if(xAxis === null || yAxis === null) {
                showValidation = true;
                validationMessage = 'Add X-axis and Y-axis to display the report.'
            }
            break;
        default:
            if(columns.length <= 0) {
                showValidation = true;
                validationMessage = 'Add at least one column to display the report.'
            }
            break;

    }
    return (
        <React.Fragment>
            <div className="position-fixed top-0 bottom-0 w-100 h-100 bg-light" style={{zIndex: 1003}}>
                <div className="d-flex justify-content-between bg-primary p-2 align-items-center">
                    <div>
                        <Button
                            color="primary"
                            className="btn-label"
                            onClick={() => onBack()}
                        >
                                <i className="ri-arrow-left-line label-icon align-middle fs-16"></i>
                                {t('reports.action_bar.btn_back')}
                        </Button>
                    </div>
                    {reportName ? <Label className="text-white m-0 fs-5">{reportName}</Label> : ''}
                    <div className="d-flex justify-content-center">
                        <Button
                            color="light"
                            className="btn-label" outline
                            onClick={() => setSaveModel(true)}
                        >
                                <i className="ri-file-excel-2-line label-icon align-middle fs-16"></i>
                                {t('reports.action_bar.btn_save_report')}
                        </Button>
                    </div>
                </div>
                <div className="d-flex h-100">
                    <div className="d-flex h-100">
                        <DataPanel
                            dataSources={dataSources}
                            setRelationships={setRelationships}
                            onAddColumn={onAddColumn}
                            onAddFilters={onAddFilters}
                            setDataSources={setDataSources}
                            onModifyGroupBy={onModifyGroupBy}
                            onModifyValue={onModifyValue}
                            onModifyCompareBy={onModifyCompareBy}
                            onModifyXAxis={onModifyXAxis}
                            onModifyYAxis={onModifyYAxis}
                            onAddField={onAddField}
                        />
                        <ControlPanel
                            columns={columns}
                            filters={filters}
                            dataSources={dataSources}
                            setChart={setChart}
                            chart={chart}
                            groupBy={groupBy}
                            value={value}
                            compareBy={compareBy}
                            xAxis={xAxis}
                            yAxis={yAxis}
                            fields={fields}
                            moveColumns={moveColumns}
                            onColumnUpdated={onColumnUpdated}
                            onColumnDelete={onColumnDelete}
                            onFilterUpdated={onFilterUpdated}
                            onFilterDelete={onFilterDelete}
                            setFilterCondition={setFilterCondition}
                            onModifyGroupBy={onModifyGroupBy}
                            onModifyValue={onModifyValue}
                            onModifyCompareBy={onModifyCompareBy}
                            setCompareBy={setCompareBy}
                            onModifyXAxis={onModifyXAxis}
                            onModifyYAxis={onModifyYAxis}
                            setXAxis={setXAxis}
                            moveFields={moveFields}
                            onFieldUpdated={onFieldUpdated}
                            onFieldDelete={onFieldDelete}
                        />
                    </div>
                    {
                        dataSources?.length ? showValidation ?
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center fs-6 fw-semibold">{validationMessage}</div> :
                            <div className="d-flex h-100 w-100 px-4 py-2">
                                <Report
                                    showResult={showResult}
                                    config={config}
                                    onRun={onRun}
                                />
                            </div> :
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center fs-6 fw-semibold">No Data Sources Selected</div>
                    }
                </div>
                {edit ? <EditModel reportName={reportName} config={config} saveModel={saveModel} setSaveModel={setSaveModel} /> : <SaveModel config={config} saveModel={saveModel} setSaveModel={setSaveModel} /> }
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(Config);
